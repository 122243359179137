const Tucannon = [
  {
    name: 'Tucannon',
    data: 'Photos',
    year: 2011,
    size: '2.89 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Stream Temperature',
    year: 2011,
    size: '47 Kb'
  },
  {
    name: 'Tucannon',
    data: 'Topographic',
    year: 2011,
    size: '544.98 Mb'
  },
  {
    name: 'Tucannon',
    data: 'Photos',
    year: 2012,
    size: '5.06 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Stream Temperature',
    year: 2012,
    size: '495 Kb'
  },
  {
    name: 'Tucannon',
    data: 'Topographic',
    year: 2012,
    size: '695.23 Mb'
  },
  {
    name: 'Tucannon',
    data: 'Photos',
    year: 2013,
    size: '6.27 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Stream Temperature',
    year: 2013,
    size: '1.80 Mb'
  },
  {
    name: 'Tucannon',
    data: 'Topographic',
    year: 2013,
    size: '1.02 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Photos',
    year: 2014,
    size: '1.90 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Stream Temperature',
    year: 2014,
    size: '2.16 Mb'
  },
  {
    name: 'Tucannon',
    data: 'Topographic',
    year: 2014,
    size: '1.15 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Photos',
    year: 2015,
    size: '1.28 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Stream Temperature',
    year: 2015,
    size: '2.41 Mb'
  },
  {
    name: 'Tucannon',
    data: 'Topographic',
    year: 2015,
    size: '1.02 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Photos',
    year: 2016,
    size: '1.46 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Stream Temperature',
    year: 2016,
    size: '1.34 Mb'
  },
  {
    name: 'Tucannon',
    data: 'Topographic',
    year: 2016,
    size: '1.10 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Photos',
    year: 2017,
    size: '1.23 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Stream Temperature',
    year: 2017,
    size: '3.65 Mb'
  },
  {
    name: 'Tucannon',
    data: 'Topographic',
    year: 2017,
    size: '1.31 Gb'
  },
  {
    name: 'Tucannon',
    data: 'Photos',
    year: 2018,
    size: '394.27 Mb'
  },
  {
    name: 'Tucannon',
    data: 'Stream Temperature',
    year: 2018,
    size: '95 Kb'
  }
];

export default Tucannon;
