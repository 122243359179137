const Umatilla = [
  {
    name: 'Umatilla',
    data: 'Photos',
    year: 2012,
    size: '131.84 Mb'
  },
  {
    name: 'Umatilla',
    data: 'Topographic',
    year: 2012,
    size: '166.22 Mb'
  },
  {
    name: 'Umatilla',
    data: 'Photos',
    year: 2013,
    size: '1.13 Gb'
  },
  {
    name: 'Umatilla',
    data: 'Stream Temperature',
    year: 2013,
    size: '108 Kb'
  },
  {
    name: 'Umatilla',
    data: 'Topographic',
    year: 2013,
    size: '311.42 Mb'
  },
  {
    name: 'Umatilla',
    data: 'Photos',
    year: 2015,
    size: '115.93 Mb'
  },
  {
    name: 'Umatilla',
    data: 'Stream Temperature',
    year: 2015,
    size: '115 Kb'
  },
  {
    name: 'Umatilla',
    data: 'Topographic',
    year: 2015,
    size: '394.82 Mb'
  }
];

export default Umatilla;
