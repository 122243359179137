const YankeeFork = [
  {
    name: 'Yankee Fork',
    data: 'Photos',
    year: 2013,
    size: '3.95 Gb'
  },
  {
    name: 'Yankee Fork',
    data: 'Stream Temperature',
    year: 2013,
    size: '1.15 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Topographic',
    year: 2013,
    size: '600.13 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Photos',
    year: 2014,
    size: '1.67 Gb'
  },
  {
    name: 'Yankee Fork',
    data: 'Stream Temperature',
    year: 2014,
    size: '1.34 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Topographic',
    year: 2014,
    size: '843.48 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Photos',
    year: 2015,
    size: '1.53 Gb'
  },
  {
    name: 'Yankee Fork',
    data: 'Stream Temperature',
    year: 2015,
    size: '1.31 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Topographic',
    year: 2015,
    size: '634.71 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Photos',
    year: 2016,
    size: '1.29 Gb'
  },
  {
    name: 'Yankee Fork',
    data: 'Stream Temperature',
    year: 2016,
    size: '1.09 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Topographic',
    year: 2016,
    size: '854.74 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Photos',
    year: 2017,
    size: '1.05 Gb'
  },
  {
    name: 'Yankee Fork',
    data: 'Stream Temperature',
    year: 2017,
    size: '1.36 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Topographic',
    year: 2017,
    size: '900.58 Mb'
  },
  {
    name: 'Yankee Fork',
    data: 'Photos',
    year: 2018,
    size: '1.18 Gb'
  },
  {
    name: 'Yankee Fork',
    data: 'Stream Temperature',
    year: 2018,
    size: '517 Kb'
  }
];

export default YankeeFork;
