import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import About from './About';
import PeopleStepper from './PeopleStepper';
import DataGridContainer from '../components/DataGridContainer';
import watershedData from '../utils/watershedData';
import programFiles from '../utils/programFiles';
import MapContainer from './map/Map';

function MainContent() {
  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 8 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              height: '732px',
              overflow: 'hidden'
            }}
          >
            <Grid item xs={6} md={5} lg={5}>
              <About />
            </Grid>

            <Grid item xs={6} md={7} lg={7}>
              <MapContainer />
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '70vh' }}>
            <DataGridContainer
              title="Watershed Datasets"
              columnGroupModel={['name', 'data']}
              dataSource={watershedData}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Box id="documents">
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                justifyContent: 'center'
              }}
            >
              <DataGridContainer
                title="Program Documents & Files"
                columnGroupModel={['category']}
                dataSource={programFiles}
              />
            </Paper>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box id="people">
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                justifyContent: 'center'
              }}
            >
              <PeopleStepper />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
export default MainContent;
