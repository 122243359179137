import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NavHashLink } from 'react-router-hash-link';

function Header() {
  const activeStyle = {
    textDecoration: 'underline',
    color: '#FFF',
    marginRight: 15
  };

  return (
    <AppBar color="primary" elevation={3}>
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          Columbia Habitat Monitoring Program
        </Typography>
        <nav>
          <a
            href="https://www.streamnet.org/home/data-maps/champ/"
            target="_blank"
            style={activeStyle}
            rel="noreferrer"
          >
            StreamNet
          </a>

          <NavHashLink smooth to="/#documents" style={activeStyle}>
            Documents
          </NavHashLink>
          <NavHashLink smooth to="/#people" style={activeStyle}>
            People &amp; Organizations
          </NavHashLink>
        </nav>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
