const data = [
  {
    organization: 'CDFW',
    people: ['Sean Gallagher', 'Elizabeth Mackey'],
    panel: 'panel1'
  },
  {
    organization: 'Cramer Fish Sciences',
    people: ['Christopher Clark'],
    panel: 'panel2'
  },
  {
    organization: 'CRITFC',
    people: [
      'Monica Blanchard',
      'Lauren Burns',
      'Casey Justice',
      'Dale McCullough',
      'Seth White',
      'Danielle Horn',
      'Kristi Olney',
      'Nancy Platt',
      'Lindsay Powell'
    ],
    panel: 'panel3'
  },
  {
    organization: 'CTUIR',
    people: [
      'Ken Burcham',
      'Kaylyn Costi',
      'Leslie Naylor',
      'Gene Shippentower',
      'Craig Contor',
      'Colette Coiner',
      'Aaron Quaempts',
      'Andrew Van Sickle',
      'Andrew Wildbill'
    ],
    panel: 'panel4'
  },
  {
    organization: 'ELR',
    people: [
      'Steve Bennett',
      'Reid Camp',
      'Andrew Hill',
      'Eric Wall',
      'Gus Wathen',
      'Nick Weber',
      'Jacob Wirtz',
      'Ross Gleason',
      'Devin Baumer',
      'Jordan Burningham',
      'John Deane',
      'Erica Gorey',
      'Bryan Hongo',
      'Alex Pitman',
      'Erika Winner'
    ],
    panel: 'panel5'
  },
  {
    organization: 'NSD',
    people: [
      `Jennifer O'Neal`,
      'Colin Riordan',
      'Roby Ventres-Pake',
      'Megan Whiteside',
      'Kaitlynn Cafferty',
      'Caroline Walls'
    ],
    panel: 'panel6'
  },
  {
    organization: 'ODFW',
    people: [
      `Christopher Horn`,
      'Ted Sedell',
      'Shelley Tattam',
      'Joshua Dowdy',
      'Marlene Cross',
      'Jonathan Downey',
      'Drayden Farci',
      'Gerrish "Ben" Willis'
    ],
    panel: 'panel7'
  },
  {
    organization: 'OSU',
    people: [`James Pearson`, 'Levi Pienovil'],
    panel: 'panel8'
  },
  {
    organization: 'Quantitative Consultants Inc',
    people: [
      `Laurel Faurot`,
      'Richie Carmichael',
      'Michelle Romportl',
      'Tulley Mackey',
      'Oliver Ott',
      'Joseph Sullivan',
      'Molly Sutton'
    ],
    panel: 'panel9'
  },
  {
    organization: 'Shoshone-Bannock Tribes',
    people: [`Evelyn Galloway`],
    panel: 'panel10'
  },
  {
    organization: 'Sitka',
    people: [`Matt Nahorniak`, 'Steve Rentmeester', 'Keith Steele', 'Matt Archibald'],
    panel: 'panel11'
  },
  {
    organization: 'Terraqua',
    people: [
      `Steve Fortney`,
      'Crissy Hill',
      'Rueben Miller',
      'Keith van den Broek',
      'Jonah Keith',
      'Tom Koski',
      'Martin Novak',
      'Becky Gehri',
      'Connor Osterlund'
    ],
    panel: 'panel12'
  },
  {
    organization: 'Tetra Tech',
    people: [`Jef Parr`],
    panel: 'panel13'
  },
  {
    organization: 'Utah State University',
    people: [`Nathaniel Hough-Snee`, 'Martha Jensen', 'Matthew Meier', 'Geoff Stevens'],
    panel: 'panel14'
  },
  {
    organization: 'Warm Springs Tribe',
    people: [`Amy Charette`, 'Joe Lemanski', 'Keith Karoglanian', 'Allison Field', 'Wendy Neal'],
    panel: 'panel15'
  },
  {
    organization: 'Watershed Solutions',
    people: [`Boyd Bouwes`, 'Jeremiah Heitke', 'Geoff Stevens'],
    panel: 'panel16'
  },
  {
    organization: 'Yakama Nation',
    people: [`Oliver Pimms`, 'Brad Parrish', 'Ryan Deknikker', 'Nathan Longoria'],
    panel: 'panel17'
  }
];
export default data;
