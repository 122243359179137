import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Marker, Popup } from 'react-map-gl';
import { blue } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';

function MarkerWithPopup({ watershed, onSelectCity }) {
  const [showPopup, setShowPopup] = useState(false);
  const { WatershedName, CenterLat, CenterLong, NumOfSites, DateRange } = watershed.properties;

  const handleClick = (event) => {
    onSelectCity(event.target._lngLat);
    setShowPopup(true);
  };

  return (
    <>
      <Marker key={WatershedName} longitude={CenterLong} latitude={CenterLat} onClick={handleClick}>
        <Avatar sx={{ bgcolor: blue[500], width: 24, height: 24, fontSize: 10 }}>
          {WatershedName.charAt(0)}
        </Avatar>
      </Marker>
      {showPopup ? (
        <Popup
          longitude={CenterLong}
          latitude={CenterLat}
          anchor="center"
          closeOnClick={false}
          onClose={() => setShowPopup(false)}
        >
          <Typography variant="subtitle2">
            <strong>Watershed:</strong> {WatershedName}
          </Typography>
          <Typography variant="subtitle2">
            <strong>Number of Sites:</strong> {NumOfSites}
          </Typography>
          <Typography variant="subtitle2">
            <strong>Years Active:</strong> {DateRange}
          </Typography>
        </Popup>
      ) : null}
    </>
  );
}

MarkerWithPopup.propTypes = {
  watershed: PropTypes.objectOf(PropTypes.any),
  onSelectCity: PropTypes.func,
}

export default MarkerWithPopup;
