import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#43a047',
      light: '#a7ca37'
    },
    secondary: {
      main: '#a7ca37'
    },
    text: {
      secondary: 'rgba(37,36,36,0.54)',
      disabled: 'rgba(166,166,166,0.38)'
    }
  },
  typography: {
    h1: {
      fontWeight: 600
    },
    h2: {
      fontWeight: 500
    },
    h5: {
      fontWeight: 600
    },
    h6: {
      fontSize: '.95rem',
      fontWeight: 400
    },
    body2: {
      paddingBottom: 15,
      lineHeight: 1.75
    }
  },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    }
  }
});

export default theme;
