const Lemhi = [
  {
    name: 'Lemhi',
    data: 'Photos',
    year: 2011,
    size: '4.56 Gb'
  },
  {
    name: 'Lemhi',
    data: 'Stream Temperature',
    year: 2011,
    size: '126 Kb'
  },
  {
    name: 'Lemhi',
    data: 'Topographic',
    year: 2011,
    size: '351.72 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Photos',
    year: 2012,
    size: '8.02 Gb'
  },
  {
    name: 'Lemhi',
    data: 'Stream Temperature',
    year: 2012,
    size: '1.79 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Topographic',
    year: 2012,
    size: '519.62 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Photos',
    year: 2013,
    size: '2.08 Gb'
  },
  {
    name: 'Lemhi',
    data: 'Stream Temperature',
    year: 2013,
    size: '927 Kb'
  },
  {
    name: 'Lemhi',
    data: 'Topographic',
    year: 2013,
    size: '938.26 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Photos',
    year: 2014,
    size: '1.49 Gb'
  },
  {
    name: 'Lemhi',
    data: 'Stream Temperature',
    year: 2014,
    size: '876 Kb'
  },
  {
    name: 'Lemhi',
    data: 'Topographic',
    year: 2014,
    size: '549.10 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Photos',
    year: 2015,
    size: '1.46 Gb'
  },
  {
    name: 'Lemhi',
    data: 'Stream Temperature',
    year: 2015,
    size: '2.11 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Topographic',
    year: 2015,
    size: '812.06 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Photos',
    year: 2016,
    size: '1.25 Gb'
  },
  {
    name: 'Lemhi',
    data: 'Stream Temperature',
    year: 2016,
    size: '3.62 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Topographic',
    year: 2016,
    size: '813.67 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Photos',
    year: 2017,
    size: '868.67 Mb'
  },
  {
    name: 'Lemhi',
    data: 'Stream Temperature',
    year: 2017,
    size: '972 Kb'
  },
  {
    name: 'Lemhi',
    data: 'Topographic',
    year: 2017,
    size: '429.28 Mb'
  },
];

export default Lemhi;
