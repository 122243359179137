const AEM = [
  {
    name: 'AEM',
    data: 'Photos',
    year: 2012,
    size: '2.75 Gb'
  },
  {
    name: 'AEM',
    data: 'Topographic',
    year: 2012,
    size: '156.94 Mb'
  },
  {
    name: 'AEM',
    data: 'Photos',
    year: 2015,
    size: '194.53 Mb'
  },
  {
    name: 'AEM',
    data: 'Topographic',
    year: 2015,
    size: '73.60 Mb'
  },
  {
    name: 'AEM',
    data: 'Photos',
    year: 2016,
    size: '311.05 Mb'
  },
  {
    name: 'AEM',
    data: 'Photos',
    year: 2017,
    size: '283.52 Mb'
  },
  {
    name: 'AEM',
    data: 'Topographic',
    year: 2017,
    size: '249.80 Mb'
  }
];

export default AEM;