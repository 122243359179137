const Minam = [
  {
    name: 'Minam',
    data: 'Photos',
    year: 2013,
    size: '1.43 Gb'
  },
  {
    name: 'Minam',
    data: 'Stream Temperature',
    year: 2013,
    size: '696 Kb'
  },
  {
    name: 'Minam',
    data: 'Topographic',
    year: 2013,
    size: '330.74 Mb'
  },
  {
    name: 'Minam',
    data: 'Photos',
    year: 2014,
    size: '656.46 Mb'
  },
  {
    name: 'Minam',
    data: 'Stream Temperature',
    year: 2014,
    size: '389 Kb'
  },
  {
    name: 'Minam',
    data: 'Topographic',
    year: 2014,
    size: '458.30 Mb'
  },
  {
    name: 'Minam',
    data: 'Photos',
    year: 2015,
    size: '577.36 Mb'
  },
  {
    name: 'Minam',
    data: 'Stream Temperature',
    year: 2015,
    size: '494 Kb'
  },
  {
    name: 'Minam',
    data: 'Topographic',
    year: 2015,
    size: '435.05 Mb'
  },
];

export default Minam;
