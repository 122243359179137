import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Header from './Header';
import MainContent from './MainContent';
import ScrollToTop from '../components/ScrollToTop';
import Footer from './Footer';

function Layout() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <Box id="back-to-top-anchor">
        <Header />
      </Box>
      <Box
        component="main"
        sx={{
          backgroundColor: '#FFF',
          flexGrow: 1,
          height: '100vh'
        }}
      >
        <MainContent />
        <Box>
          <Footer />
        </Box>
      </Box>
      <ScrollToTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon htmlColor="#FFF" />
        </Fab>
      </ScrollToTop>
    </Box>
  );
}

export default Layout;
