/* eslint-disable no-unused-vars */
import React from 'react';
import DownloadFile from '../components/DownloadFile';
import files from './files';
import { Tooltip } from '@mui/material';
import '../theme/styles.css';

const data = {
  columns: [
    { field: 'category', headerName: 'Category', width: 200 },
    {
      field: 'year',
      headerName: 'Year',
      width: 100,
      groupable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
      groupable: false,
      renderCell: (arg) => {
        if (arg.value) {
          return (
            <Tooltip describeChild title={arg.value}>
              <span className="table-cell-trucate">{arg.value}</span>
            </Tooltip>
          );
        }
        return arg.value;
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 0.5,
      groupable: false,
      renderCell: (arg) => {
        if (arg.value) {
          return (
            <Tooltip describeChild title={arg.value}>
              <span className="table-cell-trucate">{arg.value}</span>
            </Tooltip>
          );
        }
        return arg.value;
      }
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 100,
      groupable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (arg) => {
        if (arg.value) {
          return <DownloadFile filePath={arg.value} />;
        }
        return arg.value;
      }
    }
  ],

  rows: files.map((row, index) => {
    return {
      id: index,
      ...row
    };
  })
};

export default data;
