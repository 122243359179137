const BigNavarroGarcia = [
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Photos',
    year: 2011,
    size: '3.74 Gb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Topographic',
    year: 2011,
    size: '97.35 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Photos',
    year: 2012,
    size: '6.06 Gb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Stream Temperature',
    year: 2012,
    size: '155 Kb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Topographic',
    year: 2012,
    size: '269.68 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Photos',
    year: 2013,
    size: '5.83Gb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Stream Temperature',
    year: 2013,
    size: '728 Kb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Topographic',
    year: 2013,
    size: '409.52 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Photos',
    year: 2014,
    size: '337.98 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Stream Temperature',
    year: 2014,
    size: '307 Kb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Topographic',
    year: 2014,
    size: '162.18 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Photos',
    year: 2015,
    size: '499.05 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Stream Temperature',
    year: 2015,
    size: '277 Kb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Topographic',
    year: 2015,
    size: '269.62 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Photos',
    year: 2016,
    size: '343.86 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Stream Temperature',
    year: 2016,
    size: '134 Kb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Topographic',
    year: 2016,
    size: '176.98 Mb'
  },

  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Photos',
    year: 2017,
    size: '409.18 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Topographic',
    year: 2017,
    size: '186.80 Mb'
  },
  {
    name: 'Big Navarro Garcia (CA)',
    data: 'Stream Temperature',
    year: 'N/A',
    size: '46 Kb'
  }
];

export default BigNavarroGarcia;
