import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinkToDataSet({ filePath }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Link href={filePath.link} target="_blank">
        <DownloadIcon size="small" color="primary" />
      </Link>
      <Typography variant="caption" display="block" gutterBottom>
        &nbsp;&nbsp;{filePath.size}
      </Typography>
    </Box>
  );
}

LinkToDataSet.propTypes = {
  filePath: PropTypes.string
};

export default LinkToDataSet;
