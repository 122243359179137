import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FolderIcon from '@mui/icons-material/Folder';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import SecetionTitleWithIcon from './SectionTitleWithIcon';
import useKeepGroupingColumnsHidden from '../hooks/useKeepGroupingColumnsHidden';

function DataGridContainer({ title, columnGroupModel, dataSource }) {
  const INITIAL_GROUPING_COLUMN_MODEL = columnGroupModel;
  const data = dataSource;
  const apiRef = useGridApiRef();
  const columns = useKeepGroupingColumnsHidden(apiRef, data.columns, INITIAL_GROUPING_COLUMN_MODEL);

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <SecetionTitleWithIcon title={title} Icon={FolderIcon} />
      <DataGridPro
        {...data}
        apiRef={apiRef}
        columns={columns}
        rowGroupingColumnMode="multiple"
        disableSelectionOnClick
        initialState={{
          rowGrouping: {
            model: INITIAL_GROUPING_COLUMN_MODEL
          },
          sorting: {
            sortModel: [{ field: 'year', sort: 'asc' }]
          }
        }}
        experimentalFeatures={{
          rowGrouping: true
        }}
        sx={{
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main'
          },
          height: '50vh'
        }}
      />
    </Box>
  );
}

DataGridContainer.propTypes = {
  title: PropTypes.string,
  columnGroupModel: PropTypes.arrayOf(PropTypes.string),
  dataSource: PropTypes.any
};

export default DataGridContainer;
