import React from 'react';
import PropTypes from 'prop-types';
import LaunchIcon from '@mui/icons-material/Launch';

function DownloadFile({ filePath }) {
  return (
    <a href={filePath} target="_blank" rel="noreferrer">
      <LaunchIcon size="small" color="primary" />
    </a>
  );
}

DownloadFile.propTypes = {
  filePath: PropTypes.string
};

export default DownloadFile;
