import React from 'react';
import LinkToDataSet from '../components/LinkToDataset';
import Asotin from './asotin';
import AEM from './aem';
import BigNavarroGarcia from './Big-Navarro-Garcia';
import JohnDay from './John-Day';
import Lemhi from './Lemhi';
import Methow from './Methow';
import Minam from './Minam';
import SouthForkSalmon from './South-Fork-Salmon';
import Tucannon from './Tucannon';
import UpperGrandeRonde from './Upper-Grande-Ronde';
import WallaWalla from './Walla-Walla';
import Wenatchee from './Wenatchee';
import YankeeFork from './Yankee-Fork';
import Entiat from './Entiat'
import Umatilla from './Umatilla';

let rowData = [
  ...Asotin,
  ...AEM,
  ...BigNavarroGarcia,
  ...Entiat,
  ...JohnDay,
  ...Lemhi,
  ...Methow,
  ...Minam,
  ...SouthForkSalmon,
  ...Tucannon,
  ...Umatilla,
  ...UpperGrandeRonde,
  ...WallaWalla,
  ...Wenatchee,
  ...YankeeFork,
];

function getLink(params) {
  if (params.row.name) {
    let dataType = params.row.data;

    let year;
    switch (params.row.year) {
      case 'N/A':
        year = '_unclassified';
        break;
      default:
        year = params.row.year;
        break;
    }

    let watershed = '';
    switch (params.row.name) {
      case 'Big Navarro Garcia (CA)':
        watershed = 'Big-Navarro-Garcia (CA)';
        break;
      default:
        watershed = params.row.name;
        break;
    }

    return {
      link: `https://champ.streamnet.org/${watershed}/${year}/CHAMP-${watershed}-${year}-${dataType}.zip`,
      size: params.row.size
    };
  }
}

const data = {
  columns: [
    {
      field: 'name',
      headerName: 'Watershed',
      width: 250
    },
    { field: 'data', headerName: 'Data Type', width: 250 },
    {
      field: 'year',
      headerName: 'Year',
      flex: 0.5,
      groupable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'link',
      headerName: 'Download',
      sortable: false,
      groupable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      valueGetter: getLink,
      renderCell: (arg) => {
        if (arg.value) {
          return <LinkToDataSet filePath={arg.value} />;
        }
        return arg.value;
      }
    }
  ],
  rows: rowData.map((row, index) => {
    return {
      id: index,
      ...row
    };
  })
};

export default data;
