const WallaWalla = [
  {
    name: 'Walla Walla',
    data: 'Photos',
    year: 2013,
    size: '665.04 Mb'
  },
  {
    name: 'Walla Walla',
    data: 'Topographic',
    year: 2013,
    size: '51.36 Mb'
  },
  {
    name: 'Walla Walla',
    data: 'Photos',
    year: 2014,
    size: '191.17 Mb'
  },
  {
    name: 'Walla Walla',
    data: 'Stream Temperature',
    year: 2014,
    size: '89 Kb'
  },
  {
    name: 'Walla Walla',
    data: 'Topographic',
    year: 2014,
    size: '43.37 Mb'
  },
  {
    name: 'Walla Walla',
    data: 'Photos',
    year: 2015,
    size: '57.00 Mb'
  },
  {
    name: 'Walla Walla',
    data: 'Stream Temperature',
    year: 2015,
    size: '46 Kb'
  },
  {
    name: 'Walla Walla',
    data: 'Topographic',
    year: 2015,
    size: '29.98 Mb'
  }
];

export default WallaWalla;
