import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function AboutDetails() {
  return (
    <Box
      component="div"
      sx={{
        maxHeight: '350px',
        whiteSpace: 'wrap',
        overflow: 'auto',
        p: 2
      }}
    >
      <Typography variant="body2" gutterBottom component="div">
        The goal of CHaMP is to generate and implement a standard set of fish habitat monitoring
        (status and trend) methods in up to 26 watersheds across the Columbia River basin. The
        watersheds have been chosen to maximize the contrast in current habitat conditions and also
        represent a temporal gradient of expected change in condition through planned habitat
        actions. Surveys will be conducted in watersheds with perceived large juvenile life-stage
        survival gaps due to habitat impairments or that are home to existing high quality fish
        monitoring infrastructure. CHaMP implementation will occur on the spatial scale of the
        Technical Recovery Team (TRT) populations with the intention for inference on habitat
        quality and quantity at the fish population level.
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        The goal of CHaMP is to generate and implement a standard set of fish habitat monitoring
        (status and trend) methods in up to 26 watersheds across the Columbia River basin. The
        watersheds have been chosen to maximize the contrast in current habitat conditions and also
        represent a temporal gradient of expected change in condition through planned habitat
        actions. Surveys will be conducted in watersheds with perceived large juvenile life-stage
        survival gaps due to habitat impairments or that are home to existing high quality fish
        monitoring infrastructure. CHaMP implementation will occur on the spatial scale of the
        Technical Recovery Team (TRT) populations with the intention for inference on habitat
        quality and quantity at the fish population level.
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        CHaMP is being built around a single habitat monitoring protocol with a program-wide
        approach to data collection and management. The protocol is fish-centric, i.e., it measures
        habitat relevant to salmonids of interest under the BiOp. The CHaMP protocol is structured
        around a general understanding of the link between habitat attributes and specific life
        history requirements of salmonids managed under the 2008 BiOp. These fish are likely not
        only responding to watershed and reach conditions, but also to the conditions of individual
        channel units within reaches. Accordingly, the CHaMP protocol has been developed to capture
        habitat features that drive fish population biology.
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        CHaMP methods draw from many existing protocols as well as novel approaches to collecting
        and analyzing channel geomorphological data. The protocol is designed to maintain the rapid
        nature of existing stream habitat protocols, and to collect data within a geomorphological
        hierarchy spanning multiple spatial scales, i.e., within-channel unit, channel unit,
        geomorphic reach, watershed and subbasin scales. The protocol employs spatially continuous
        sampling strategies to conduct precise topographic surveys from which digital elevation
        models (DEMs) can be produced. These topographic surveys are augmented by other data (e.g.,
        channel classification, fish cover, substrate composition, distribution and embeddedness,
        large woody debris, solar input and water temperature, stream discharge, water chemistry,
        riparian structure, and site-level human influence) that help to characterize aspects of
        channel units that influence site-scale fish production potential.
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        CHaMP data will be used to evaluate the quantity and quality of tributary fish habitat
        available to salmonids across the Columbia River basin in wadeable, perennial streams below
        natural impassible barriers within TRT population boundaries. The stream habitat data
        generated by CHaMP will be used in conjunction with salmonid growth, survival, abundance and
        productivity data to estimate fish-habitat relationships and assess the impact of habitat
        management actions on fish population processes across the Columbia River Basin. In addition
        to meeting FCRPS BiOp prescriptions (RPA 56.3), CHaMP supports habitat restoration,
        rehabilitation, and conservation action performance assessments and adaptive management
        requirements of the 2008 FCRPS BiOp.
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        In 2017 the main CHaMP field program was closed and decisions were made to migrate datasets
        to StreamNet&#39;s DataStore.
      </Typography>
    </Box>
  );
}

export default AboutDetails;
