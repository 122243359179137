import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function SecetionTitleWithIcon({ title, Icon }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 1
      }}
    >
      <Icon color="secondary" fontSize="large" sx={{ mr: 1 }} />
      <Typography variant="h4" color="text.secondary">
        {title}
      </Typography>
    </Box>
  );
}

SecetionTitleWithIcon.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.object
};

export default SecetionTitleWithIcon;
