const Entiat = [
  {
    name: 'Entiat',
    data: 'Photos',
    year: 2011,
    size: '11.44 Gb'
  },
  {
    name: 'Entiat',
    data: 'Topographic',
    year: 2011,
    size: '1.26 Gb'
  },
  {
    name: 'Entiat',
    data: 'Photos',
    year: 2012,
    size: '12.42 Gb'
  },
  {
    name: 'Entiat',
    data: 'Stream Temperature',
    year: 2012,
    size: '163 Kb'
  },
  {
    name: 'Entiat',
    data: 'Topographic',
    year: 2012,
    size: '1.71 Gb'
  },
  {
    name: 'Entiat',
    data: 'Photos',
    year: 2013,
    size: '18.99 Gb'
  },
  {
    name: 'Entiat',
    data: 'Stream Temperature',
    year: 2013,
    size: '1.19 Mb'
  },
  {
    name: 'Entiat',
    data: 'Topographic',
    year: 2013,
    size: '2.89 Gb'
  },
  {
    name: 'Entiat',
    data: 'Photos',
    year: 2014,
    size: '3.47 Gb'
  },
  {
    name: 'Entiat',
    data: 'Stream Temperature',
    year: 2014,
    size: '1.74 Mb'
  },
  {
    name: 'Entiat',
    data: 'Topographic',
    year: 2014,
    size: '2.03 Gb'
  },
  {
    name: 'Entiat',
    data: 'Photos',
    year: 2015,
    size: '3.18 Gb'
  },
  {
    name: 'Entiat',
    data: 'Stream Temperature',
    year: 2015,
    size: '2.41 Mb'
  },
  {
    name: 'Entiat',
    data: 'Topographic',
    year: 2015,
    size: '2.42 Gb'
  },
  {
    name: 'Entiat',
    data: 'Photos',
    year: 2016,
    size: '3.13 Gb'
  },
  {
    name: 'Entiat',
    data: 'Stream Temperature',
    year: 2016,
    size: '1.52 Mb'
  },
  {
    name: 'Entiat',
    data: 'Topographic',
    year: 2016,
    size: '2.48 Gb'
  },
  {
    name: 'Entiat',
    data: 'Photos',
    year: 2017,
    size: '805.90 Mb'
  },
  {
    name: 'Entiat',
    data: 'Stream Temperature',
    year: 2017,
    size: '2.47 Mb'
  },
  {
    name: 'Entiat',
    data: 'Topographic',
    year: 2017,
    size: '653.49 Mb'
  },
  {
    name: 'Entiat',
    data: 'Topographic',
    year: 'N/A',
    size: '18.53 Mb'
  }
];

export default Entiat;