import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './views/Layout';
import NoMatch from './views/NoMatch';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default App;
