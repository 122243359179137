const Wenatchee = [
  {
    name: 'Wenatchee',
    data: 'Photos',
    year: 2011,
    size: '2.73 Gb'
  },
  {
    name: 'Wenatchee',
    data: 'Topographic',
    year: 2011,
    size: '353.33 Mb'
  },
  {
    name: 'Wenatchee',
    data: 'Photos',
    year: 2012,
    size: '4.37 Gb'
  },
  {
    name: 'Wenatchee',
    data: 'Stream Temperature',
    year: 2012,
    size: '404 Kb'
  },
  {
    name: 'Wenatchee',
    data: 'Topographic',
    year: 2012,
    size: '510.89 Mb'
  },
  {
    name: 'Wenatchee',
    data: 'Photos',
    year: 2013,
    size: '4.33 Gb'
  },
  {
    name: 'Wenatchee',
    data: 'Stream Temperature',
    year: 2013,
    size: '346 Kb'
  },
  {
    name: 'Wenatchee',
    data: 'Topographic',
    year: 2013,
    size: '890.50 Mb'
  },
  {
    name: 'Wenatchee',
    data: 'Photos',
    year: 2014,
    size: '1.66 Gb'
  },
  {
    name: 'Wenatchee',
    data: 'Stream Temperature',
    year: 2014,
    size: '844 Kb'
  },
  {
    name: 'Wenatchee',
    data: 'Topographic',
    year: 2014,
    size: '1.18 Gb'
  },
  {
    name: 'Wenatchee',
    data: 'Photos',
    year: 2015,
    size: '1.49 Gb'
  },
  {
    name: 'Wenatchee',
    data: 'Stream Temperature',
    year: 2015,
    size: '712 Kb'
  },
  {
    name: 'Wenatchee',
    data: 'Topographic',
    year: 2015,
    size: '1.19 Gb'
  },
  {
    name: 'Wenatchee',
    data: 'Photos',
    year: 2016,
    size: '1.32 Gb'
  },
  {
    name: 'Wenatchee',
    data: 'Stream Temperature',
    year: 2016,
    size: '958 Kb'
  },
  {
    name: 'Wenatchee',
    data: 'Topographic',
    year: 2016,
    size: '1.16 Gb'
  },
  {
    name: 'Wenatchee',
    data: 'Photos',
    year: 2017,
    size: '822.87 Mb'
  },
  {
    name: 'Wenatchee',
    data: 'Stream Temperature',
    year: 2017,
    size: '1.39 Mb'
  },
  {
    name: 'Wenatchee',
    data: 'Topographic',
    year: 2017,
    size: '956.56 Mb'
  }
];

export default Wenatchee;
