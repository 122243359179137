const Methow = [
  {
    name: 'Methow',
    data: 'Photos',
    year: 2011,
    size: '3.58 Gb'
  },
  {
    name: 'Methow',
    data: 'Stream Temperature',
    year: 2011,
    size: '53 Kb'
  },
  {
    name: 'Methow',
    data: 'Topographic',
    year: 2011,
    size: '1.34 Gb'
  },
  {
    name: 'Methow',
    data: 'Photos',
    year: 2012,
    size: '6.37 Gb'
  },
  {
    name: 'Methow',
    data: 'Stream Temperature',
    year: 2012,
    size: '153 Kb'
  },
  {
    name: 'Methow',
    data: 'Topographic',
    year: 2012,
    size: '1.14 Gb'
  },
  {
    name: 'Methow',
    data: 'Photos',
    year: 2013,
    size: '4.93 Gb'
  },
  {
    name: 'Methow',
    data: 'Stream Temperature',
    year: 2013,
    size: '282 Kb'
  },
  {
    name: 'Methow',
    data: 'Topographic',
    year: 2013,
    size: '1.70 Gb'
  },
  {
    name: 'Methow',
    data: 'Photos',
    year: 2014,
    size: '1.44 Gb'
  },
  {
    name: 'Methow',
    data: 'Stream Temperature',
    year: 2014,
    size: '1.13 Mb'
  },
  {
    name: 'Methow',
    data: 'Topographic',
    year: 2014,
    size: '1.89 Gb'
  },
  {
    name: 'Methow',
    data: 'Photos',
    year: 2015,
    size: '1.43 Gb'
  },
  {
    name: 'Methow',
    data: 'Stream Temperature',
    year: 2015,
    size: '1.06 Mb'
  },
  {
    name: 'Methow',
    data: 'Topographic',
    year: 2015,
    size: '1.65 Gb'
  },
  {
    name: 'Methow',
    data: 'Photos',
    year: 2016,
    size: '1.33 Gb'
  },
  {
    name: 'Methow',
    data: 'Stream Temperature',
    year: 2016,
    size: '1.05 Mb'
  },
  {
    name: 'Methow',
    data: 'Topographic',
    year: 2016,
    size: '1.80 Gb'
  },
  {
    name: 'Methow',
    data: 'Photos',
    year: 2017,
    size: '724.64 Mb'
  },
  {
    name: 'Methow',
    data: 'Stream Temperature',
    year: 2017,
    size: '1.09 Mb'
  },
  {
    name: 'Methow',
    data: 'Topographic',
    year: 2017,
    size: '1.54 Gb'
  },
];

export default Methow;
