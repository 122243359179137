const files = [
  {
    category: 'Training and Tutorials',
    name: 'GeoOptix Data Broker',
    year: undefined,
    description:
      'The Data Broker operates on the laptop and serves as a broker to move files between CHaMPMonitoring.org website, the broker, and the iPads.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/GeoOptix-Data-Broker-97309a7c-8f2b-40b1-8d81-63773ba4e2ce.pdf'
  },
  {
    category: 'Resources',
    name: 'Stream Temps for Priority Sites: Methow, Wenatchee, Entiat',
    year: undefined,
    description: 'Stream Temperature Logger Locations',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/StreamTemp_Priority_Sites_Methow_Wenatchee_Entiat-c187e53e-7384-40b9-a3f0-2c38e7b09b5a.xlsx'
  },
  {
    category: 'Training and Tutorials',
    name: 'Quality Assurance - Stream Temp - Validating Data Upload',
    year: undefined,
    description: 'Video of validating stream temp quality assurance',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Quality-Assurance-StreamTemp_1_ValidatingDataUpload-88955787-2877-4f68-bd49-d38cd62cf372-9de4ac2d-0ee2-4c4b-b63c-8171a3fcf83d.wmv'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Quality Assurance meeting November 11, 2017',
    year: 2017,
    description: 'Video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Quality-Assurance-2017-11-07-CHaMP-call-2-7e70aaa7-6e13-4c85-8ff1-b20f08d06d2c.wmv'
  },
  {
    category: 'Workshops',
    name: 'Quality Assurance meeting October 31, 2017',
    year: 2017,
    description: 'Video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Quality-Assurance-2017-10-31-CHaMP-call-1-a8b87584-2a2c-47f1-83bd-dc9e92272d5a.wmv'
  },
  {
    category: 'Training and Tutorials',
    name: 'Video setting status stream temprature',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Quality-Assurance-StreamTemp_4_SettingStatus-041860f0-632b-4673-b2af-17fe91d8f904-06b9cce9-a328-417d-965c-09133cabba59.wmv'
  },
  {
    category: 'Training and Tutorials',
    name: 'Video Stream Temperature Anomalies',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Quality-Assurance-StreamTemp_3_Anomalies-edec23f6-c8df-4365-be5f-c18f7a9c05ad-88d7a33e-165e-4c35-9839-c207754e00da.wmv'
  },
  {
    category: 'Training and Tutorials',
    name: 'Video Stream Temperature Tools Basics',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Quality-Assurance-StreamTemp_2_ToolBasics-162e53ee-3cdc-44f0-8a75-c0f45fcd4417-65e8cc71-ddc5-4974-958c-90cbffaab0e9.wmv'
  },
  {
    category: 'How To Guides',
    name: 'CHaMP 2014 Stream Temperature Quality Assurance Protocol',
    year: 2014,
    description: 'Quality Assurance - Stream Temperature 2014 Guidance',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CM.org-StreamTempQAProtocol_2014-c87e0e3a-82e6-4851-8730-fcae7d68a4bf.pdf'
  },
  {
    category: 'How To Guides',
    name: 'CHaMP Topographic Processing Quick Guide 2017',
    year: 2017,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP_Topographic_Processing_QuickGuide_2017-40f618e2-3d0c-4b48-9280-68d906375923.pdf'
  },
  {
    category: 'How To Guides',
    name: 'CHaMP Quality Assurance Guidelines',
    year: 2016,
    description:
      'This document provides guidance on performing end-of-season quality assurance using the functionality on CHaMPMonitoring.org. The quality assurance process will be most efficient if you first ensure that auxiliary and topographic data for all of your sites has been uploaded to CM.org using the Data Broker.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP_QualityAssuranceGuidelines_2016_20161006-8664b4f8-f213-474d-b59c-751eafdb24cc.pdf'
  },
  {
    category: 'How To Guides',
    name: 'Data Quality Assurance (QA) Procedures for 2016 Field Season',
    year: 2016,
    description:
      'In 2014, CHaMP modified how side channels are delineated during topographic post-processing by adding a multi-threaded centerline in the main channel and all qualifying wetted side channels. The objective of this document is to outline the steps used to check the accuracy of side channel delineation within the topographic survey.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP_QA_SideChannels_MultipleWettedPolygons_Topo_Photo_Upload_2016-e717bf40-d92d-467c-9b03-7523142e04f7.pdf'
  },
  {
    category: 'How To Guides',
    name: 'ForeSight Processing Guide',
    year: 2013,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/ForeSight_Processing_Guide-5a53c085-3351-43b0-91ee-0af24570d326.pdf'
  },
  {
    category: 'How To Guides',
    name: 'Post Processing - Solemtric SunEye',
    year: undefined,
    description:
      'This section describes the necessary steps for editing SunEye images and transferring data from the SunEye to the CHaMP database.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Post-Processing-Solemtric-SunEye-67119b9e-a82b-4c3e-a9ee-b9eed5cec54d.pdf'
  },
  {
    category: 'How To Guides',
    name: 'End of Day: Transfer from Field Devices to Laptop Data Broker',
    year: undefined,
    description: 'Post Processing - Data Download Cheatsheet',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Post-Processing-Data-Download-Cheatsheet-e27f6540-8d4f-4eb0-8521-c4b6a0a6a1c6.pdf'
  },
  {
    category: 'Resources',
    name: 'Transect Photos data collection sheet',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/photos_20140620.pdf'
  },
  {
    category: 'Resources',
    name: 'Pool Tail Fines data collection sheet',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/pooltailfines_20140620.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP QAQC Meeting 1',
    year: 2016,
    description: 'Oct 12, 2016 Meeting video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/2016-10-12_11.09_CHaMP_QAQC_Meeting_1.wmv'
  },
  {
    category: 'Workshops',
    name: 'CHaMP QAQC 2',
    year: 2015,
    description: 'October 21, 2015 Meeting video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/2015-10-21-11.34-CHaMP_QAQC_2.wmv'
  },
  {
    category: 'Workshops',
    name: 'CHaMP QA RBT fixes',
    year: 2014,
    description: 'December 3, 2014 Meeting video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/2014-12-03-CHaMP_QA_RBT-fixes.wmv'
  },
  {
    category: 'How To Guides',
    name: 'CHaMP Metric QA',
    year: 2016,
    description: 'Reviewing metrics in the CHaMP_Workbench: CHaMP QA 2016',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP_MetricQA_2016_20161111-03c2b918-1722-4622-b127-402e73bcb97e.pdf'
  },
  {
    category: 'How To Guides',
    name: 'ArcGIS/ForeSight User Guide',
    year: 2013,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/ArcGIS_ForeSight_Toolbar_Guide-e450f79a-d0f5-42dd-aa51-8f433c3d798f.pdf'
  },
  {
    category: 'How To Guides',
    name: 'CHaMP Nikon Survey Repair',
    year: 2015,
    description:
      'This document describes the process for repairing a CHaMP survey that has errors in Z values due to a processing bug in versions of the CHaMP Processing Toolbar (5.09 and older). Surveys with only a few points (20 or fewer) should use these instructions to modify an already processed survey. Surveys with a greater number of points should be reprocessed completely.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP-Nikon-Survey-Repair-dffa1750-45c1-4f73-a9ff-e034deee6bfe.pdf'
  },
  {
    category: 'Resources',
    name: 'Riparian structure form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/riparian_20140620.pdf'
  },
  {
    category: 'Resources',
    name: 'Site Setup & Width Category Form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/setup_widthcat_20140620.pdf'
  },
  {
    category: 'Resources',
    name: 'Undercut form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/undercut_20140620.pdf'
  },
  {
    category: 'Resources',
    name: 'Air and Water Temperature Data Form ',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Air_water_20140620.pdf'
  },
  {
    category: 'Resources',
    name: 'Benchmark Form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/benchmarks_20140620.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP QAQC Meeting 2',
    year: 2016,
    description: 'October 26, 2016 CHaMP meeting video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/2016-10-26_11.04_CHaMP_QAQC_Meeting_2.wmv'
  },
  {
    category: 'Resources',
    name: 'Pebble Count Form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/pebbles_20140620.pdf'
  },
  {
    category: 'Resources',
    name: 'Monument Form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/monuments_20140620.pdf'
  },
  {
    category: 'Resources',
    name: 'Channel Units data form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Channel-Units_20140620.pdf'
  },
  {
    category: 'Resources',
    name: 'Discharge data sheet',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/discharge_20140620.pdf'
  },
  {
    category: 'Resources',
    name: '	Drift Invertebrates Form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/drift_chem_20140620.pdf'
  },
  {
    category: 'Resources',
    name: '	Large woody debris form',
    year: 2014,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/lwd_20140620.pdf'
  },
  {
    category: 'Resources',
    name: 'Marker and control points data form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/marker_ctrlpoints_20140620.pdf'
  },
  {
    category: 'GIS Data',
    name: 'Big Creek Middle Fork CHaMP Geo Database',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Big_Creek_MFSalmon_CHaMP_GDB_package-7306b8ec-617c-4967-ad99-f909d11a32f0-2.zip'
  },
  {
    category: 'Resources',
    name: 'List of all Salmon populations within CHaMP watersheds',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WATERSHEDS-AllProgramWatersheds.csv'
  },
  {
    category: 'GIS Data',
    name: 'Yankee Fork CHaMP Geo Database',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Yankee_Fork_CHaMP_GDB_package-f0310107-1ce1-4d70-a840-178d202c9b44.zip'
  },
  {
    category: 'Resources',
    name: 'UGRR Restoration Activities at CHaMP Sites',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/UGRR-Restoartion-Activities-at-CHaMP-Sites-200e5a7b-b214-4089-8b3b-f2b5f0b3beb0.pdf'
  },
  {
    category: 'Resources',
    name: 'Tucannon Site List Data Information',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/TucannonSiteList_Information_20180211-001291a0-78e9-409d-8a02-0f09b3c04889.xlsx'
  },
  {
    category: 'GIS Data',
    name: 'CHaMP Asotin Geodatabase',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/Asotin_CHaMP_GDB_package-8b9f4ce2-f698-4349-9aa8-ef0a17ba5eaf.zip'
  },

  {
    category: 'Resources',
    name: 'Macroinvertebrates - Blank Sample Jar Labels',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Macroinvertebrates-Blank-Sample-Jar-Labels-a18120f6-8a31-4ddb-a041-7e47b0a548c9.pdf'
  },
  {
    category: 'GIS Data',
    name: 'Wenatchee CHaMP Geo Database',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Wenatchee_CHaMP_GDB_package-834b8047-9179-4bd7-8d74-75cf7adb543c.zip'
  },
  {
    category: 'GIS Data',
    name: 'Minam CHaMP Geo Database',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Minam_CHaMP_GDB_package-533190d4-bf65-4d1c-a76b-19a7e9e7c6b5.zip'
  },
  {
    category: 'GIS Data',
    name: 'South Fork Salmon CHaMP Geo Database',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/South_Fork_Salmon_CHaMP_GDB_package-50076e3c-dbc9-4649-ae95-d42588d08561.zip'
  },
  {
    category: 'GIS Data',
    name: 'Tucannon CHaMP Geo Database',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Tucannon_CHaMP_GDB_package-501dedf0-7c6a-41e5-947e-165259687c6b.zip'
  },
  {
    category: 'GIS Data',
    name: 'Entiat CHaMP Geo Database',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Entiat_CHaMP_GDB_package-1e207edd-fef9-4c5b-85ca-892ccaac027e-1.zip'
  },
  {
    category: 'GIS Data',
    name: 'Big Navarro-Garcia CHaMP Geo Database',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Big_Navarro-Garcia_CHaMP_GDB_package-6cd0f71c-7f50-47d1-900b-0cd65356cd66-1.zip'
  },
  {
    category: 'Resources',
    name: 'Standard Survey Site (SSS) form',
    year: 2013,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/SurveyStandardSite_1.1-c5caafd5-5e47-49fa-bc22-c1e3b7e939fb.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP TS Template',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_TS_Template-69735d5d-7277-4679-850a-0f9a5b365df6.zip'
  },
  {
    category: 'Resources',
    name: 'Surveying - Rodman Methods and Guidance',
    year: undefined,
    description: 'CHaMP Point Method Collection',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Surveying-Rodman-Methods-and-Guidance-647a4aa3-b2f8-478f-a0bc-56d1c4d93aad.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'CHaMP ForeSight Tutorial',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_ForeSight_Tutorial-58ae8dc3-672b-4acd-9f89-e0f900cda596.zip'
  },
  {
    category: 'Field Equipment Manuals',
    name: 'Scouting - Loading Waypoints to GPS Unit',
    year: undefined,
    description: 'Garmin GPS Unit - Loading Waypoints',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Scouting-Loading-Waypoints-to-GPS-Unit-a5ce5d5b-c80b-4f9a-aa77-75a9bb89b150.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP Metadata READ ME',
    year: 2015,
    description: 'About CHaMP Metrics',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP-metadata-READ-ME-5.6-14520505-03b6-4a20-8f31-acf2aa824cae.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP Considerations for Reporting Back to the ISRP',
    year: 2013,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/ISRPreporting_SMW_20131205-cf8e10ff-0377-4806-b99e-31fa29c282e9.pdf'
  },

  {
    category: 'Resources',
    name: 'Integration of Airborne Lidar and Ground Survey Data within the CHaMP Monitoring Program',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Lidar_1.0-930d4c5c-177e-49f7-9790-450e0a8f2afe.pdf'
  },
  {
    category: 'Resources',
    name: 'Scouting - Survey Work Flow Form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Scouting-Survey-Work-Flow-Form-e6672dfa-5d82-44a1-84a3-61a18defa8be.pdf'
  },
  {
    category: 'Resources',
    name: 'Benchmark Evaluation Dichotomous Key',
    year: 2013,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/BenchmarkEvaluation_1.3-5aee6c54-8352-4f46-a845-d29c414d3bfc.pdf'
  },

  {
    category: 'Resources',
    name: 'Survey Instrument Recommendation for 2016',
    year: 2016,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/InstrumentRecommendations_0.1-dbe4c4c2-817a-4e7c-8839-6106e024db3f.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP Macroinvertebrate Chain-of-Custody Form',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_MacroinvertChainOfCustodyForm-919b4630-204f-470b-b659-62e37de97ed3.pdf'
  },
  {
    category: 'Tucannon',
    name: 'Tucannon Site History Metrics',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/TucannonSiteHistory_Metrics-7e5242fd-65e7-44d0-82ee-652b8b18dc7b.xlsx'
  },
  {
    category: 'How To Guides',
    name: 'Managing User Accounts in CHaMP Data Management System',
    year: undefined,
    description:
      'The CHaMP Data Management System was built to meet the needs of the CHaMP program.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Accounts-and-Permissions-on-CM.org-4df57691-08c7-4d02-8e5d-dcf321e7ac98.pdf'
  },
  {
    category: 'Resources',
    name: 'Variance Decomposition',
    year: 2011,
    description: 'Conceptual framework, Summary of datasets',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/VarianceDecomp_Larsen-5618ae21-d1ef-4823-a01a-b82c2653404e.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP Status and Trend 2011-2014',
    year: 2011,
    description:
      'This document contains a set of plots and a large table summarizing the status and trend results for selected CHaMP metrics from 2011-2014, as discussed in the Status and Trend Summary report within the annual CHaMP-ISEMP report.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP-Status-and-Trend-2011_2014-9171b40a-caf3-42ad-82a9-106804fcbac1.pdf'
  },
  {
    category: 'Reports',
    name: 'CHaMP Summer 7dAM Temperature Table',
    year: undefined,
    description: 'CHaMP Stream Temperature Metadata',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/StreamTempSummer7dAM_Metadata-b9d4e7eb-3f8a-453f-adee-e4744dbc51bb.pdf'
  },
  {
    category: 'Resources',
    name: 'Restoration Documentation',
    year: undefined,
    description: 'Transfer from Field Devices to Laptop Data Broker',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/UGRR-Restoartion-Activities-at-CHaMP-Sites-200e5a7b-b214-4089-8b3b-f2b5f0b3beb0-1.pdf'
  },
  {
    category: 'How To Guides',
    name: 'Post Processing - Data Download Cheatsheet',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Post-Processing-Data-Download-Cheatsheet-ccd56758-677c-4220-89d1-21bcb5b1d98e.pdf'
  },
  {
    category: 'Resources',
    name: 'Lemhi River Effectiveness Monitoring (ISEMP/CHaMP)',
    year: undefined,
    description:
      'Using Fish and Habitat data in the ISEMP Watershed. Model to evaluate the Lemhi Conservation Plan',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/ISEMPWtrshdModelLemhi_JWhite-2336fa90-fc8f-480a-9ad8-94447e93bd66.pdf'
  },
  {
    category: 'Resources',
    name: 'Development of a Rapid Geomorphic Assessment Procedure',
    year: undefined,
    description: 'powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/DevRapidGeomorphAssessProc_NBouwes-d9e65363-3f50-4be7-a144-c1274f44c493.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Site CFD Modeling: Modeled results and Validation Data Comparisons',
    year: 2013,
    description: 'CHaMP site Computational Fluid Dynamic (CFD) / Hydraulic modeling powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/HydraulicModelingUpdate_MN_20131204-0425b3b6-a9f9-441d-b137-c724a5ad4c23.pdf'
  },
  {
    category: 'Tucannon',
    name: 'Tucannon River Monitoring ',
    year: 2018,
    description: 'powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/TucannonMonitoringExample_RRNW_ShortCourse_2018-b2d690e6-f63d-4a22-bf0a-4ef24adf6b31.pdf'
  },
  {
    category: 'Resources',
    name: 'Net Rate Energy Intake (NREI) Model',
    year: undefined,
    description: 'powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/NREImodel_AHill-3ebb6ce5-9c0a-4a42-a2d1-50fa59e6a0ba.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'CHaMP / ISEMP Data Analysis User Guide',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/SPSurvey_CHaMP_UserGuide-85efbc50-14ff-46af-a9bd-5a771ae0eb0e.pdf'
  },
  {
    category: 'Field Equipment Manuals',
    name: 'Hitch Planning',
    year: undefined,
    description:
      'A hitch is simply defined as a collect of sites visited by an organization during a set of days or weeks',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CM.org-HitchPlanning-372b3d36-57a3-4055-93d5-f90544575086.pdf'
  },
  {
    category: 'Field Equipment Manuals',
    name: 'Scouting - Stream Temperature Logger',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Scouting-Stream-Temperature-Logger-d6447ab6-347d-4743-af99-7134ac44f84c.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Day 1',
    year: 2015,
    description: ' Columbia Habitat Monitoring Program: A day in the life powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/06_Hill-098e9510-8618-4089-be45-036be133cc49.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Day 1',
    year: 2015,
    description: 'Why CHaMP? powerpoint about the background of CHaMP program',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/01_Jordan-7c5d631b-6dc8-434a-a71a-bcfb84761c40.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP DEM based protocol powerpoint',
    year: 2013,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_DEM-basedProtocol_NB_20131203-654a1099-ab48-46f7-a69b-3f10ca95545e.pdf'
  },
  {
    category: 'Workshops',
    name: 'Bioenergetics',
    year: undefined,
    description:
      'Modeling the Food Web Response to Restoration in the Methow River Using CHaMP Data powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-06-Bioenergetics-Ryan-Bellmore-be46beaa-d8bd-4111-ac44-b03fd382b46a.pptx'
  },
  {
    category: 'Field Equipment Manuals',
    name: 'Surveying - Introduction to Surveying Manual 1.0',
    year: undefined,
    description:
      'This manual is an introduction to Champ Survey equipment and methodology. The manual is designed to be utilized as a precursor to Champ Camp. The student is expected to read and study all of the material contained herein prior to arrival at Champ Camp. The Champ Camp Topographic training modules will support and supplement this information.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Surveying-Introduction-To-Surveying-Manual-1.0-03190d6e-d879-4bd6-b743-191a8b499b6f.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015 workshop: June 4, Presentation 6 - Hydraulic Modeling',
    year: 2014,
    description: 'Introduction to CHaMP Hydraulic Modeling powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay3_4_HydraulicModel_Nahorniak-cc8c73a4-7bf4-40d4-b34a-e096ccd06ad3.pdf'
  },
  {
    category: 'Workshops',
    name: `CHaMP 2012: Introduction and What's New`,
    year: 2012,
    description: 'Powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_IntroWhatIsNew2012_Ward-dcb782c2-9857-4f24-91a4-8ec7631ef443.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'Quality Assurance - Stream Temp Validating Data Upload',
    year: undefined,
    description: 'Video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Quality-Assurance-StreamTemp_1_ValidatingDataUpload-88955787-2877-4f68-bd49-d38cd62cf372.wmv'
  },
  {
    category: 'Training and Tutorials',
    name: 'Quality Assurance - Stream Temp Tool Basics',
    year: undefined,
    description: 'Video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Quality-Assurance-StreamTemp_2_ToolBasics-162e53ee-3cdc-44f0-8a75-c0f45fcd4417.wmv'
  },
  {
    category: 'Training and Tutorials',
    name: 'Quality Assurance - Stream Temp Setting Status',
    year: undefined,
    description: 'Video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Quality-Assurance-StreamTemp_4_SettingStatus-041860f0-632b-4673-b2af-17fe91d8f904.wmv'
  },
  {
    category: 'Training and Tutorials',
    name: 'Quality Assurance - Stream Temp Anomalies',
    year: undefined,
    description: 'Video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Quality-Assurance-StreamTemp_3_Anomalies-edec23f6-c8df-4365-be5f-c18f7a9c05ad.wmv'
  },
  {
    category: 'Workshops',
    name: 'Day 1 - 04 - Key Management Question 1 - ODFW and CRITFC',
    year: 2010,
    description:
      'Integrating Habitat and Fish Information to Inform Wise Management Decisions and Assessment of Salmon Population Recovery Actions',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-1-04-Key-Management-Question-1-ODFW-and-CRITFC-b06a1934-3a4b-4feb-8747-46c6004b5c0d.pptx'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015',
    year: 2015,
    description: 'Advanced Modules Day 3: Fish Habitat Models and Beyond',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay3_5_FHMNREI_Bailey-Wheaton-McHugh-9b5b30cf-6b45-410d-a819-9835fb6d8343.pdf'
  },
  {
    category: 'Resources',
    name: 'Bridge Creek Intensively Monitored Watershed',
    year: undefined,
    description: 'Partnering With Beavers In Stream Restoration powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/BridgeCkIMWupdate_NBouwes-1ad5a5c5-3df6-4189-af4c-ef9a3be87b76.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP Briefing Materials 2010 PNAMP Steering Committee',
    year: 2010,
    description:
      'This document provides a brief overview of a proposed Columbia Basin-scale fish habitat monitoring program including program elements, map, and budget estimates. The Columbia Habitat Monitoring Program (CHaMP) is being proposed by the Integrated Status and Effectiveness Monitoring Program (ISEMP) and eight collaborating agencies1 to help Bonneville Power Administration meet the requirements of the 2008 Federal Columbia River Power System Biological Opinion (BiOp, RPA 56.3). This program will provide information on the status/trends in habitat conditions, and will support habitat restoration, rehabilitation and conservation actions, performance assessments, and the adaptive management requirements of the 2008 FCRPS BiOp.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP_BriefingMaterials_20100818toPNAMP_SC-1e986b7c-7658-41b3-886d-3ed197ffa837.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'Surveying - Total Station Setup',
    year: undefined,
    description: 'video',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Surveying-Total-Station-Setup-785d93b3-91d8-44e1-80f3-aedd4792b39f.wmv'
  },
  {
    category: 'Tucannon',
    name: 'Tucannon River Geomorphic Assessment & Habitat Restoration Study',
    year: 2011,
    description:
      'This assessment is intended to strengthen the technical understanding of existing physical conditions and geomorphic processes in the basin in order to identify and prioritize habitat restoration opportunities.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Revised-Tucannon-Geomorphic-Assessment_040611_with-Appendices-6102e596-ac26-4ca3-aa85-d47844848296.pdf'
  },
  {
    category: 'Workshops',
    name: ' (CHaMP) 2012 Pilot Project Post-Season Workshop Agenda',
    year: 2012,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_2012PostSeasonMtgAgenda_20121128-379cbf62-7025-4bba-84c9-4c26fcc8420b.pdf'
  },
  {
    category: 'Reports',
    name: 'CHaMP Response To Comments On Protocol 20110926',
    year: undefined,
    description:
      'The focus of this document is to address comments received on the CHaMP Salmonid Habitat Protocol Version 1.0. In addition to the comments on the CHaMP Habitat Protocol, described below, principle recommendations from the Northwest Power and Conservation Council (NPCC) regarding the entire CHaMP program from their Research, Monitoring and Evaluation and Artificial Production Project Review Decision Document June 2011 are included.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMPResponseToCommentsOnProtocol20110926Revised-a5ca9449-b7a1-42fb-99d8-fc27ee2ab065.pdf'
  },
  {
    category: 'Reports',
    name: 'CHaMP 2011 Pilot Lessons Learned Report',
    year: undefined,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP_2011Pilot_LessonsLearnedRept_20120331_HiRes_ToBPA-ed59a254-8b06-4a76-996c-acc2d4d48ac6.pdf'
  },
  {
    category: 'Workshops',
    name: 'Exploring CHaMP Temperature Logger Data',
    year: 2012,
    description: 'Exploring CHaMP Temperature Logger Data powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_TempLoggerData_McNyset-34db75f8-1ac1-4914-b992-22eb4959200d.pdf'
  },
  {
    category: 'Workshops',
    name: 'Fish Habitat Status in the Upper Columbia',
    year: 2012,
    description: 'Objective: summarize fish habitat data at various spatial scales',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/DispHabStatusManyScales_Fortney-7937741b-2fd3-433d-b6b2-d1929751b368.pdf'
  },
  {
    category: 'Workshops',
    name: 'Site and Watershed Standard Errors vs. Measurement Protocol Changes',
    year: 2012,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/SiteWatershedErrorsVsMetrics_Nahorniak-accd89f5-1ce3-4890-9faf-5b2aa356dd30.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Workshop: Fish Habitat Modeling',
    year: 2012,
    description: 'Power Point',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/FishHabitatModeling_See-14aaf06d-fe98-4e30-b766-d40b1df86677.pdf'
  },
  {
    category: 'Workshops',
    name: `Estimating Status for CHaMP's Status and Trend Program`,
    year: 2012,
    description: 'Power Point',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/EstimatingStatus_Starcevich-b960924d-6b0a-462b-a546-85c128535316.pdf'
  },
  {
    category: 'Reports',
    name: 'CHaMP Pilot Project 2012 Completion Report',
    year: 2012,
    description:
      '2012 Program Recap Status and Trend Highlights Data Analysis Highlights 2013 Planning',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMPCompletionReport2012_Ward-d501c3ce-4059-494c-be21-f3c60cbf0a2d.pdf'
  },
  {
    category: 'Reports',
    name: 'Presentation to ISAB/ISRP - Status Report',
    year: undefined,
    description: 'Update on the pilot project',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP_ISAB_ISRP_20130111-8740b4f6-958b-4791-9e71-9d7668c0a225.pdf'
  },
  {
    category: 'Workshops',
    name: 'ISEMP-CHaMP Fish-habitat relationship development presentation',
    year: 2013,
    description:
      'ISEMP-CHaMP Fish-habitat relationship development presentation to ISAB ISRP January 11, 2013. Ultimate goal of ISEMP and CHaMP is to develop fish-habitat relationships that feed management decision-making processes',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/ISAB_ISRP_ISEMP_20130111-bff29fd4-7679-4336-9f87-07adf0087dff.pdf'
  },
  {
    category: 'Resources',
    name: 'PIBO-CHaMP Comparison for Federal Caucus 2013',
    year: 2013,
    description:
      'A comparison of two stream habitat monitoring programs, PIBO and CHaMP. Why was this project undertaken?',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/PIBO-CHaMPforFedCaucus20130125-a17c1c80-ebcf-4fe5-9640-8089e5e55e8c.pdf'
  },
  {
    category: 'Reports',
    name: 'CHaMP 2012 Second Year Lessons Learned Report 2013',
    year: 2013,
    description:
      'This document is a summary of the lessons learned from work conducted by the Columbia Habitat Monitoring Program (CHaMP) in the 2012 pilot year.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP_2012SecondYrLLRept_20130505_ToBPA-cf5c2187-fb06-4b70-9b40-1c85f2ed3422.pdf'
  },
  {
    category: 'Workshops',
    name: 'Status of Geomorphic Change Dectection ',
    year: 2012,
    description: 'Status of Geomorphic Change Dectection from 2011 to 2012 Surveys powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/GCD_2011to2012_Wheaton-6c196ca2-73a6-4d77-b755-607624fe0903.pdf'
  },
  {
    category: 'Resources',
    name: 'Evaluation of Suneye vs. Heat Source as Means to Monitor Riparian Canopy',
    year: 2012,
    description:
      'Evaluation of Solmetric SunEye vs. Heat Source Insolation Measurements as a Means to Monitor Trends in Riparian Canopy power point',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/SuneyeVsHStoMonitorCanopy_McCullough-52769726-c146-460e-a48b-3a0d282d452c.pdf'
  },
  {
    category: 'Resources',
    name: 'Development Progress Update Shear Zones',
    year: 2013,
    description: 'Development Progress Update: Shear Zones - December4, 2013 powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/ShearZonesUpdate_AH_20131204-0b6a2cf7-54e2-4c28-af50-7ad52d2516e8.pdf'
  },
  {
    category: 'Resources',
    name: 'Measurements to Indicators',
    year: 2013,
    description: 'Measurements to Indicators - December 5, 2013 powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/MeasToIndicators_PL_20131205-9985a872-1cb0-4b80-8e1b-466235b464cd.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP 2013 - Why are we here?',
    year: 2013,
    description: 'CHaMP 2013 Post-season Workshop Introduction powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_2013_WkshpIntro_CJ_20131203-d6bb0297-4985-4e06-8d55-b47356f5c780.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Study Design',
    year: 2013,
    description: 'CHaMP Study Design Summary: Design Types powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_StudyDesignSummary_CV_20131203-baaa7963-5654-44a3-a7c6-7146bc890037.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP Field Season 2013',
    year: 2013,
    description:
      'CHaMP 2013 Data Management Review: QA, data logger, broker, CM.org - December 3, 2013 powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_EndOfSeason_SR_20131203-c183877a-8981-419a-8ca0-96aca637d431.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP 2013 Total Station Issues Form',
    year: 2013,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_2013_TS_IssuesForm-be412a39-c710-499b-a60d-bad5a8de33fa.pdf'
  },
  {
    category: 'Resources',
    name: 'RBT within CHaMP',
    year: 2013,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_RBTupdate_PB_20131203-4a2be4dc-f5ea-48d5-9d4f-c6291c8d443e.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'CHaMP Topographic Processing Tool tutorial',
    year: 2013,
    description:
      'This Tutorial guides the user through the complete processing of a survey using the CHaMP Topographic Processing Toolbar in GIS. The user should have completed or already be familiar with the concepts presented in the ForeSight and GIS Tutorials before working through the material presented in this Tutorial.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_Workflow_Tutorial-f5503ded-5a91-43c0-aac2-52d7d848b4de.zip'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Geomorphic Unit Derivation',
    year: 2013,
    description: 'CHaMP 2013 Post Season Workshop: Geomorphic Unit Derivation powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_GUs_JoeW_20131203-76cd3a5d-a2af-4d8f-ba83-dad81ea2109c.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Harnessing DEMs for Quantifying Fish Habitat',
    year: 2013,
    description: 'Harnessing DEMs for Quantifying Fish Habitat powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_HarnessingDEMs_JW-PB_20131203-1e84de70-6eaa-4ab9-a30f-de8077742ef4.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP/ISEMP Watershed Production Model',
    year: 2013,
    description: 'Watershed Production Model Development Update - December 4, 2013 powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WatershedProdModDev_20131204-29543f7d-a697-422c-b16c-0d0069f80a0b.pdf'
  },
  {
    category: 'Workshops',
    name: 'Fish Habitat Modeling',
    year: 2013,
    description: 'Fish-Habitat Modeling Update - December 4, 2013 power point',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/FishHabModelingUpdate_KS_20131204-5b9bcd3d-1ee1-4311-92f8-018e5b44b601.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP - ISEMP Habitat Models',
    year: 2013,
    description: 'Habitat Suitability Models powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/HabitatSuitModels_CMcG_20131204-dbf5ea49-99e2-4460-bd61-73ce0956f6e8.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Metric House Keeping',
    year: 2013,
    description: 'CHaMP Metric Evaluation Framework - December 5, 2013 powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/MetricEvalFrmwk_CV-BB_20131205-42c9062f-2a8e-459f-938b-275fcbd9c2d5.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP - ISEMP Analysis and Synthesis Workshop: Welcome Powerpoint',
    year: 2014,
    description: `To review how CHaMP-ISEMP habitat and fish data, analyses, and synthesis products could be used to support the FCRPS BiOp management community's needs.`,
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-1-01-Workshop-Introduction-Chris-Jordan-c51080ff-4ef9-4438-952d-3159b454199e.pptx'
  },
  {
    category: 'Resources',
    name: 'CHaMP 2014 Stream Temperature Quality Assurance Protocol',
    year: 2014,
    description: 'Quality Assurance - Stream Temperature 2014 Guidance',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CM.org-StreamTempQAProtocol_2014-95b36ef8-4b33-484a-807a-7a934ce3e025.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP 2014 - Review of Topographic Data: Side Channels',
    year: 2014,
    description: 'Quality Assurance - Side Channels 2014',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CM.org-Quality-Assurance-of-Side-Channel-2014-acc8c6d5-c44e-4bc0-82ff-9626f41877f5.pdf'
  },
  {
    category: 'Resources',
    name: 'Quality Assurance (QA) on CHaMPMonitoring.org',
    year: 2014,
    description: 'Quality Assurance - Guidelines 2014',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CM.org-QualityAssuranceGuidelines-2014-c2e4f2c1-d748-44db-a71a-cb14bc6ded02.pdf'
  },
  {
    category: 'Resources',
    name: 'MBACI Protocol for Monitoring the Combined Effectiveness of Bank Stablizationa and Livestock Exclusion Projects',
    year: 2014,
    description:
      'This document details the monitoring design, procedures and quality assurance steps necessary to document and report the effectiveness of Instream Habitat Projects at the Project site scale.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/MBACI-Protocol-for-Monitoring-the-Combined-Effectiveness-of-Bank-Stablizationa-and-Livestock-Exclusion-Projects-16a960e9-9e29-4d7b-b68b-d10aba105fcb.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'CHaMP GIS Tutorials',
    year: 2013,
    description:
      'This collection of tutorials (and associated datasets) are intended to teach basic GIS methods used in the CHaMP Topographic Processing Workflow.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_GIS_Tutorials-302a5e4a-bda8-4af6-939b-422272363307.zip'
  },
  {
    category: 'Resources',
    name: 'Macroinvertebrate Sampling Update 2013',
    year: 2013,
    description: 'Macroinvertebrate Sampling Update poweropint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/MacroinvertUpdate_NB_20131204-cf021225-2cd7-4b29-ad52-a8bbd7602295.pdf'
  },
  {
    category: 'Workshops',
    name: '2014 State of the Science Workshop Day 2 - 07 - River Styles - Joe Wheaton',
    year: 2014,
    description:
      'CHaMP and ISEMP State of the Science Workshop Day 2 powerpoint. River Styles Framework Adapting River Styles Framework to Support Regional Tributary Habitat Salmonid Management Needs',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-07-River-Styles-Joe-Wheaton-ac53302c-3e33-443e-aa3d-936cce28a883.pptx'
  },
  {
    category: 'Workshops',
    name: '2014 State of the Science Workshop Day 1 - 03 - Key Management Question 1 - Nick Bouwes',
    year: 2014,
    description:
      'CHaMP and ISEMP State of the Science Workshop Day 1. River Styles Framework Adapting River Styles Framework to Support Regional Tributary Habitat Salmonid Management Needs',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-1-03-Key-Management-Question-1-Nick-Bouwes-5c68e497-cac5-4e90-8e1d-855b945ffb29.pptx'
  },
  {
    category: 'Workshops',
    name: '2014 State of the Science Workshop Day 1 - 05 - Key Management Question 3 - Chris Beasley',
    year: 2014,
    description: 'CHaMP and ISEMP State of the Science Workshop. KMQ 3 - Tools and Products',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-1-05-Key-Management-Question-3-Chris-Beasley-ef1912e2-ac74-4ddd-b37a-7a2352ed5e06.pptx'
  },
  {
    category: 'Workshops',
    name: '2014 State of the Science Workshop Day 1 - 02 - Overview - Chris Jordan',
    year: 2014,
    description:
      'CHaMP and ISEMP State of the Science Workshop (Day 1): Supporting a BPA Framework to Help Meet Regional Salmonid Management Requirements',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-1-02-Overview-Chris-Jordan-ecd51c55-ee6a-4d70-9709-274dcad546c6.pptx'
  },
  {
    category: 'Workshops',
    name: 'Review Study Designs and Metrics',
    year: 2014,
    description: `CHaMP and ISEMP Analysis and Synthesis Workshop (Day 2): A Review of CHaMP's interoperability, study design and metrics`,
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-09-Review-Study-Designs-and-Metrics-Boyd-Bouwes-bb196949-1dc1-4f0c-9107-a6b427a7bc34.pptx'
  },
  {
    category: 'Workshops',
    name: 'Salmon Lifecycle Model',
    year: 2014,
    description:
      'CHaMP and ISEMP Analysis and Synthesis Workshop: Salmonid Life Cycle Model power point',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-08-Salmon-Lifecycle-Model-Carl-Saunders-605e3db8-11a9-4ba1-80e5-9bf58084ef69.pptx'
  },
  {
    category: 'Workshops',
    name: `Integrating ODFW's Aquatic Inventories Project HabRate Model with CHaMP`,
    year: 2014,
    description: `CHaMP and ISEMP State of the Science Workshop: Integrating ODFW's Aquatic Inventories Project HabRate Model with CHaMP powerpoint`,
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-05-HabRate-Ted-Sedell-7de46e01-004f-4938-9605-9b4792f1fa84.pptx'
  },
  {
    category: 'Workshops',
    name: 'Flume Results',
    year: 2014,
    description: 'CHaMP and ISEMP State of the Science Workshop: Flume Demonstration power point',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-00-Flume-Results-Joe-Wheaton-92b21e3b-828c-4035-94b7-55b5a45d8ecf.pptx'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Invertebrate Monitoring',
    year: 2014,
    description:
      'CHaMP and ISEMP State of the Science Workshop: CHaMP Invertebrate Monitoring powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-02-CHaMP-Bugs-2014-Nick-Bouwes-043321b4-6921-4441-9ee2-7c37655e1ac8.pptx'
  },
  {
    category: 'Workshops',
    name: 'BRT Extrapoloation',
    year: 2014,
    description:
      'CHaMP and ISEMP State of the Science Workshop: Extrapolating Across a Stream Network powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-04-BRT-Extrapoloation-Kevin-See-46cb09ed-3842-466a-9209-51a6e33c56d2.pptx'
  },
  {
    category: 'Workshops',
    name: 'NREI',
    year: 2014,
    description:
      'CHaMP and ISEMP State of the Science Workshop: Net Rate of Energy Intake powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-03-NREI-Nick-Bouwes-2709b8ab-ddba-419c-be5e-f7104a71b2f6.pptx'
  },

  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Advanced Modules for Using CHaMP Tools, Models, and Products',
    year: 2015,
    description: 'Final agenda and June 2-4 sessions agenda',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMPCamp2015_WorkshopAgenda-Modules_Final-f4b7e24e-967f-4ed4-82c2-2cadd3df55e8.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: CHaMP and ISEMP Analysis and Synthesis Workshop',
    year: 2015,
    description:
      'Final agenda and June 2-4 sessions: Science Supporting the Summary Products: Vignettes & Status Updates powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-2-01-A-La-Carte-bd7833cd-aa2e-4b47-abfd-b7690617322c.pptx'
  },
  {
    category: 'Resources',
    name: 'CHaMP 2015 Surveying Reminders and Issues',
    year: 2015,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/SurveyIssues2015_1.0-52e59a38-db28-40d2-8673-ef621d23dcaf.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP Drift Processing Protocol',
    year: 2015,
    description: 'Macroinvertebrate Drift Sample Processing Standard Operating Procedure',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/2015_CHAMP_Drift_Processing_Draft-373124e8-9254-45c5-974c-bb96c90f4046.pdf'
  },
  {
    category: 'Resources',
    name: 'CHaMP 2015 Field Data Forms',
    year: 2015,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMP_2015_FieldDataForms_20150624-5fd8cfa5-9e93-4f07-a67c-0526fd94f8e0.zip'
  },
  {
    category: 'Reports',
    name: 'CHaMP 2013 — Third Year Lessons Learned ',
    year: 2013,
    description:
      'Columbia Habitat Monitoring Program: 2013 — Third Year Lessons Learned Project Synthesis Report',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP2013LLReport_FinalForPISCES_20150213-05d435e2-bff7-441e-90ad-9eca8af65760.pdf'
  },
  {
    category: 'Reports',
    name: 'Combined ISEMP CHaMP Annual Report 2014',
    year: 2014,
    description:
      'Combined Annual Report for the Integrated Status and Effectiveness Monitoring Program and Columbia Habitat Monitoring Program: 2014. Prepared by ISEMP and CHaMP for the Bonneville Power Administration. Published by Bonneville Power Administration.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CY2014_CombinedISEMPCHaMPAnnualRpt_Final-To-BPA-2-047c962b-cc02-4fcc-a385-b8882b4d17f2.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP and ISEMP State of the Science Workshop Day 1 - 04 - Key Management Question 2 - Joe Wheaton',
    year: 2014,
    description: 'CHaMP and ISEMP State of the Science Workshop powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day-1-04-Key-Management-Question-2-Joe-Wheaton-cb33efa0-c90c-4854-b09f-c5ba8b3b36f4.pptx'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Modeling Habitat Attributes',
    year: 2015,
    description:
      'Modeling relationships between CHaMP metrics and landscape characteristics in the Upper Grande Ronde River basin powerpoint',
    link: 'https://www.streamnet.org/workshopday3_1a_modelinghabitatattributes_justice-61f3620a-f225-4aff-a5dd-20728dafc56c/'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Workshop Abstracts',
    year: 2015,
    description: 'All presentation abstracts from 2015 CHaMP Camp',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/CHaMPCamp2015_WorkshopAbstracts-a7859bc6-4073-4836-89b1-a003a530cdb5.zip'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Final agenda and driving tour logistics',
    year: 2015,
    description:
      'This session of “CHaMP Camp”, the 10-day field training designed to teach collection of standardized salmonid habitat data using the Columbia Habitat Monitoring Program (CHaMP) protocol, marks the start of the final year of data collection under the three year CHaMP pilot project (BPA Project# 2011-006-00). The Day 1 agenda generally outlines the topics that will be presented in the morning. In the afternoon, attendees will tour sites along Catherine Creek for streamside CHaMP protocol demonstrations and to participate in discussions about fish ecology, freshwater tributary habitat, and related topics with project staff and collaborators.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Day1_AgendaDriveTourLogistics_Final_20150601-8e6b587d-58fc-4bc1-bf06-f9cc2cbe7a45.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'GIS Training 2015 TIN Visualization Tutorial',
    year: 2015,
    description:
      'The ability to visualize a Triangulated Irregular Network (TIN) is important in order to understand the topographic features it represents. Since the process of surveying a sampling site can introduce errors, blunders or mis-interpolated topography, it is important to recognize the characteristics and features of a clean TIN so that these errors can be easily recognized. This tutorial is designed to guide the user though the features of a relatively clean (error-free) TIN representing a surveyed stream channel.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/GIS_Training_2015_TIN_Visualization_tutorial-e1210b80-0b0f-4f53-90c7-7b2769e5e329.zip'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Workbench Presentation 1',
    year: 2015,
    description:
      'CHaMP Camp 2015 workshop: June 3, Presentation 1 - River Bathymetry Toolkit powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay2_RBT_Bailey-3888acf6-1fb6-49a9-ad0b-76e15dce0798.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Workbench Presentation 2',
    year: 2015,
    description: 'CHaMP Camp 2015 workshop: June 3, Presentation 2 - CHaMP Workbench powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay2_CHaMPWorkbench_Bailey-2b2cda84-7c6f-4b31-97fb-56160c4b1c8a.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015 Workshop: Sampling and Data',
    year: 2015,
    description: 'An Introduction to CHaMP Sampling and Data Analysis powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay1_DesignModelBasedAnalyses_Nahorniak-da4ba336-1cd1-492e-af1a-79222fc48315.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Data Availability',
    year: 2015,
    description: 'CHaMP data availability: finding what you need powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay1_CHaMPDataAvailability_Volk-f8065085-1794-491f-b45e-402a158ceb6e.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: CHaMP Stream Temperature Models',
    year: 2015,
    description: 'CHaMP Stream Temprature Models powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay1_TempModel_McNyset-8322e4bf-a217-4ee4-9642-76d5938ea3f0.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Airborne Lidar & CHaMP Surveys',
    year: 2015,
    description: 'Airborne Lidar & CHaMP Surveys powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay3_2_LiDaR_DeMeurichy-14752f9c-068a-4677-aeb3-1adff0611624.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: Measurements to Metrics to Models',
    year: 2015,
    description: 'Measurements to Metrics to Models powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/07_BouwesB-bcfb3edc-b9f3-4efb-a0bf-a4b8e2f1ab76.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015: BPA Action Effectiveness Monitoring',
    year: 2015,
    description: 'BPA Action Effectiveness Monitoring powerpoint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/05_ONeal-3b41e8bf-d3ca-4fec-b027-38a5d9251692.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015 Workshop: Structural Equation Models',
    year: 2015,
    description: 'Structural Equation Modeling of Fish Habitat Relationships power point',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay3_1c_HabRate_Sedell-62cff416-e460-4524-b9d9-470bf6c1f169.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015 Workshop: Fish Management',
    year: 2015,
    description: 'CHaMP Data to Fish Management power point',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/03_McHugh-f0aeb755-108c-4cc3-9f53-e798c4f919dd.pdf'
  },
  {
    category: 'Tucannon',
    name: 'Tucannon RS Stages 1 & 2 Preliminary Report',
    year: 2015,
    description: `We are progressing through a geomorphic assessment of the Tucannon watershed using a modified version of the River Styles framework. The River Styles framework is a hydrologic and geomorphic classification system which provides tools for interpreting river character, behavior, geomorphic condition, and recovery potential (Brierley and Fryirs, 2005). It consists of a series of four stages that includes 1) an identification of the unique suite of River Styles (i.e., reach types) within the watershed, 2) an assessment of the current condition of the watershed, given the historical context, 3) predictions about the recovery potential and finally 4) implications for watershed management and restoration planning. This framework is widely used by watershed managers in Australia and New Zealand and is gaining traction in the Columbia River Basin. Our geomorphic assessment of the Tucannon River Watershed does not strictly adhere to the River Styles framework in that we do not explicitly incorporate all elements of Stages 2 - 4 (e.g., measured cross sections) and we bolster the condition assessment with spatially explicit network based models of riparian and floodplain condition.`,
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Tucannon_RS_Stages_1_2_Preliminary_Report-1bb7e35d-5c83-46fa-bdef-21cdc4979761.pdf'
  },
  {
    category: 'Tucannon',
    name: 'Tucannon Monitoring Meeting',
    year: 2015,
    description: 'PowerPoint Presentationint',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/TucMonitoring_SRSRB_Nov2017-84cce5d9-cdae-432b-ad7d-570ca320360c.pdf'
  },
  {
    category: 'Tucannon',
    name: 'Tucannon River Restoration Effectiveness Monitoring: 2015 Results',
    year: 2015,
    description: `The Snake River Salmon Recovery Board (SRSRB) is coordinating the development of restoration designs and implementation of restoration actions in the Tucannon River primarily focused on ESA listed spring Chinook. A Geomorphic Assessment and Habitat Restoration study of the Tucannon mainstem has been completed to assess historic and current conditions, and to assess and prioritize restoration actions best suited to address ecological concerns identified in the Snake River Salmon Recovery Plan (AQEA 2011, SRSRB 2011). Priority restoration actions identified during the Tucannon Assessment began in 2011. The main restoration actions proposed are levee removal/setbacks, side-channel reconnection, and the addition of large woody debris (LWD).`,
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/Tucannon_RestorationEffectiveness_MonitoringResults2015-8074429f-7b9a-4414-b012-6af37ba94d7a.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015 Workshop',
    year: 2015,
    description: 'Why are we sampling where we are and what will CHaMP data be used for?',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/04_Jordan-8310d18d-ede7-4f24-a751-8baea3925885.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'GIS Training 2015 Vector Editing Tutorial',
    year: 2015,
    description: 'GIS Training 2015 Vector Editing tutorial documents and files',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/GIS_Training_2015_Vector_Editing_tutorial-1c726eef-3423-435d-a3dc-5e7b440d4cbf.zip'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015 Workshop',
    year: 2015,
    description: 'How do you account for fish habitat with a total station?',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/02_Wheaton-5de3137c-72d5-445f-bd1d-8f58926dfe7c.pdf'
  },
  {
    category: 'Workshops',
    name: 'CHaMP Camp 2015 Workshop',
    year: 2015,
    description: 'Workshop Day 2: Geomorphic Change Detection',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay2_GCD_Wheaton-e746d9c2-963b-4700-8c4d-bcc345d856d7.pdf'
  },
  {
    category: 'Workshops',
    name: '2015 CHaMP Camp - Advanced Workshop',
    year: 2015,
    description: 'Workshop Day 3: - Geomorphic Unit Tool',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay3_3_GUT_Wheaton-a2077869-b6f7-453d-915d-2a5b66f3124e.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'GIS Training 2015_Toolbar Workflow',
    year: 2015,
    description: `This Tutorial guides the user through the complete processing of a survey using the 2015 CHaMP Topographic Processing Toolbar in GIS. The user should already be familiar with basic GIS concepts before working through the material presented in this Tutorial. If the user is unfamiliar with GIS basics, additional tutorials are available through the CHaMP Topo Toolbar website (http://champtools.northarrowresearch.com/). This Tutorial uses sample data, but describes all processing steps and tools that may be needed during normal CHaMP topographic survey data processing.`,
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/GIS_Training_2015_Toolbar_Workflow_tutorial-001c9dc8-747a-4f86-a2c9-b4e2563a90ee.zip'
  },
  {
    category: 'Tucannon',
    name: 'Tucannon Site List Information',
    year: 2018,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/TucannonSiteList_Information_20180211-001291a0-78e9-409d-8a02-0f09b3c04889-1.xlsx'
  },
  {
    category: 'Reports',
    name: 'NPCC Decision Letter to BPA',
    year: 2018,
    description: `On December 11, 2018 the Council completed a progress review of 25 projects engaged in research activities under the Fish and Wildlife Program. The Council's recommendations to Bonneville regarding these projects are detailed in the attached document and summarized here. The list of project recommendation includes the NPCC recommendation to BPA on the closing
        out of CHaMP (BPA project 2011-006-00)`,
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/TucannonSiteList_Information_20180211-001291a0-78e9-409d-8a02-0f09b3c04889-1.xlsx'
  },
  {
    category: 'Reports',
    name: `NPCC memo on Update on discussions with managers/sponsors on tributary habitat monitoring
        and review of M&E approaches`,
    year: 2017,
    description:
      'Memo to NPCC Fish and Wildlife Committee members from Council Staff (June 6, 2017). Council and Bonneville staff will provide a summary of discussions with Program habitat managers and sponsors regarding tributary habitat monitoring and evaluation and the contribution of the three BPA monitoring projects, Integrated Status and Effectiveness Monitoring Program (ISEMP), Columbia Habitat Monitoring Program (CHaMP), and Action Effectiveness Monitoring (AEM). The discussions focused on understanding: 1) the current approaches used to guide habitat restoration actions and assess action effectiveness; 2) existing gaps and potential options for improving monitoring and evaluation around the region; and 3) the use of tools and products developed through CHaMP, ISEMP and AEM.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/12/NPCC-ChaMP-Memo-2017_0613_f02.pdf'
  },
  {
    category: 'Tucannon',
    name: 'Tucannon Temperature Logger Status',
    year: 2017,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/TucannonTemperatureLoggerStatus2017-04ed107b-40c6-43a5-9edc-325092f6981b.xlsx'
  },
  {
    category: 'Reports',
    name: 'ISEMP CHaMP Combined Annual Report 2016 Draft',
    year: 2016,
    description: `This report presents an update on the implementation of Bonneville Power Administration's (BPA) Integrated Status and Effectiveness Monitoring Program (ISEMP; BPA Project 2003-017-00) and the Columbia Habitat Monitoring Program (CHaMP; BPA Project 2011-006-00) during 2016. The work conducted under ISEMP and CHaMP covers key populations within the Upper Columbia River Spring-Run Chinook ESU and steelhead DPS, the Snake River Spring/Summer-Run Chinook ESU and steelhead DPS, and the Middle Columbia River Steelhead DPS. ISEMP/CHaMP. 2017. Integrated Status and Effectiveness Monitoring Program (ISEMP) and Columbia Habitat Monitoring Program (CHaMP) Annual Combined Technical Report, January - December 2016, BPA Projects 2003-017-00 and 2011-006-00, 93 Electronic Pages.`,
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/2016_ISEMP_CHaMP_CombinedAnnualReport_DRAFT_20170616-60a7aa2b-5e45-4fc2-870c-39476372b73a.pdf'
  },
  {
    category: 'Resources',
    name: '2016 CHaMP Protocol',
    year: 2016,
    description:
      'This document was funded by Bonneville Power Administration‟s Columbia Habitat Monitoring Program (CHaMP; Project #2011-006) for use by BPA‟s CHaMP project. This version incorporates comments received from the CHaMP 2015 Post Field Season Surveys.',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/2016_CHaMP_Protocol_201605015-634e917b-4347-4153-9973-5b933836e7ec.pdf'
  },
  {
    category: 'Resources',
    name: 'LWD Meta Data',
    year: 2016,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/LWDmetadata_20160905-864aaee6-68e2-48be-854a-6e654d96b79b.xlsx'
  },
  {
    category: 'Resources',
    name: `An Overview of the Columbia Habitat Monitoring Program's (CHaMP) Spatial-Temporal Design Framework`,
    year: 2016,
    description: `CHaMP's primary objectives were to describe the status and trends of habitat attributes that are important for Endangered Species Act listed (ESA-Listed) Chinook salmon Oncorhynchus tshawytscha and anadromous steelhead Oncorhynchus mykiss growth and survival. To efficiently meet the data collection and analysis needs of the program, CHaMP applied a common spatial-temporal design framework to the selection of monitoring sites in each CHaMP watershed, standardized habitat monitoring methods across the subbasins, employed an integrated, web-based system for documenting methods and designs, implemented a web-based system for data management, and collaborated with other agencies conducting habitat monitoring in these subbasins to leverage existing designs and locations.`,
    link: 'https://www.streamnet.org/wp-content/uploads/2022/10/CHaMP-incorporates-master-sample_final_20161205-e0e379b6-de4c-461f-9096-d6f682021427.pdf'
  },
  {
    category: 'Workshops',
    name: 'Geomorphic & Network Context 2015 CHaMP Camp Advanced Workshop powerpoint Day 1',
    year: 2015,
    description: '',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/WorkshopDay1_GeomorphNetworkContext_Wheaton-Volk-fb66205b-29a4-4eb0-abc7-1505963fc5f8.pdf'
  },
  {
    category: 'Training and Tutorials',
    name: 'GIS Training 2015 Advanced TIN Tutorial',
    year: 2015,
    description: 'CHaMP - Advanced TIN Editing files and documents',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/GIS_Training_2015_Advanced_TIN_tutorial-bb5e4dd0-521a-4f62-9d66-740e69ddb0eb.zip'
  },
  {
    category: 'Training and Tutorials',
    name: 'GIS Training 2015 - Intro To GIS Tutorial',
    year: 2015,
    description: 'GIS Training 2015 Intro To GIS tutorial documents and map files',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/GIS_Training_2015_IntroToGIS_tutorial-cb70665a-e1a3-474c-9d37-1d1eb7e1d339.zip'
  },
  {
    category: 'Training and Tutorials',
    name: 'GIS Training 2015 TIN Editing Tutorial',
    year: 2015,
    description: 'GIS Training 2015 TIN Editing tutorial documents and files',
    link: 'https://www.streamnet.org/wp-content/uploads/2022/11/GIS_Training_2015_TIN_Editing_tutorial-06f18489-7ded-4f1c-9c7c-fd075b4b2805.zip'
  }
];

export default files;