/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import LaunchIcon from '@mui/icons-material/Launch';
import AboutDetails from './AboutDetails';

function About() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ mr: 3, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h3" color="text.secondary">
          CHaMP data is now available on StreamNet.
        </Typography>
        <Button
          href="https://www.streamnet.org/home/data-maps/champ/champ-files/"
          target="_blank"
          variant="outlined"
          sx={{ width: '100%', my: 1 }}
          endIcon={<LaunchIcon />}
        >
          View Watershed Data on StreamNet
        </Button>

        <div>
          <Collapse orientation="vertical" in={!expanded}>
            <Typography variant="body2" gutterBottom>
              The 2008 Biological Opinion on the Federal Columbia River Power System identified
              implementation of tributary habitat restoration projects as a means to offset
              mortality imposed by the FCRPS on anadromous salmonids.
            </Typography>
            <Typography variant="body2" gutterBottom>
              In 2010, the Bonneville Power Administration began development of the Columbia Habitat
              Monitoring Program to meet FCRPS Action Agency prescriptions for habitat monitoring
              (FCRPS BiOp RPA 56.3). The BPA is working with the National Oceanic and Atmospheric
              Administration and other regional fish management agencies to implement CHaMP.
            </Typography>
          </Collapse>

          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon htmlColor="#FFF" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                color: '#FFF',
                bgcolor: (theme) => theme.palette.primary.main
              }}
            >
              <Typography variant="caption">READ MORE...</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AboutDetails />
            </AccordionDetails>
          </Accordion>
        </div>
      </Box>
    </Box>
  );
}
export default About;
