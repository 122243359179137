const UpperGrandeRonde = [
  {
    name: 'Upper Grande Ronde',
    data: 'Photos',
    year: 2011,
    size: '13.04 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Stream Temperature',
    year: 2011,
    size: '1.42 Mb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Topographic',
    year: 2011,
    size: '1.14 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Photos',
    year: 2012,
    size: '9.53 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Stream Temperature',
    year: 2012,
    size: '1.71 Mb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Topographic',
    year: 2012,
    size: '993.21 Mb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Photos',
    year: 2013,
    size: '12.68 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Stream Temperature',
    year: 2013,
    size: '1.69 Mb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Topographic',
    year: 2013,
    size: '1.89 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Photos',
    year: 2014,
    size: '4.19 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Stream Temperature',
    year: 2014,
    size: '2.81 Mb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Topographic',
    year: 2014,
    size: '1.76 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Photos',
    year: 2015,
    size: '3.26 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Stream Temperature',
    year: 2015,
    size: '2.92 Mb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Topographic',
    year: 2015,
    size: '1.64 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Photos',
    year: 2016,
    size: '3.45 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Stream Temperature',
    year: 2016,
    size: '3.05 Mb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Topographic',
    year: 2016,
    size: '2.12 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Photos',
    year: 2017,
    size: '3.47 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Stream Temperature',
    year: 2017,
    size: '4.34 Mb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Topographic',
    year: 2017,
    size: '2.19 Gb'
  },
  {
    name: 'Upper Grande Ronde',
    data: 'Stream Temperature',
    year: 'N/A',
    size: '90 Kb'
  }
];

export default UpperGrandeRonde;
