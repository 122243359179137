import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Pagination from '@mui/material/Pagination';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Avatar, Divider, ListItemAvatar, ListItemIcon, Slide } from '@mui/material';
import SecetionTitleWithIcon from '../components/SectionTitleWithIcon';
import peopleData from '../utils/peopleData';

export default function PeopleStepper() {
  const [data] = useState(peopleData);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(1);
  const containerRef = useRef(null);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentOrg = data.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Box sx={{ height: '40vh' }}>
      <SecetionTitleWithIcon title="People and Organizations" Icon={PeopleAltIcon} />
      <Paper elevation={3} sx={{ width: '100%', mt: 2 }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {currentOrg.map((element) => (
            <Box sx={{ width: '100%', m: 2 }} key={element.organization}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CorporateFareIcon color="primary" size="large" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h5" color="primary">
                        {element.organization}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
              <Divider />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  overflow: 'hidden',
                  alignItems: 'flex-start',
                  height: '30vh'
                }}
                ref={containerRef}
              >
                {element.people.map((person) => {
                  return (
                    <Box key={person}>
                      <Slide direction="up" in container={containerRef.current}>
                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main }}>
                                <PersonIcon htmlColor="#FFF" size="small" />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="body1" color="text.primary">
                                  {person}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                      </Slide>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ))}
        </Box>
        <Divider />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', py: 3 }}>
          <Pagination
            count={data.length}
            page={currentPage}
            onChange={handleChange}
            color="primary"
          />
        </Box>
      </Paper>
    </Box>
  );
}
