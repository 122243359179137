const SouthForkSalmon = [
  {
    name: 'South Fork Salmon',
    data: 'Photos',
    year: 2011,
    size: '5.58 Gb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Topographic',
    year: 2011,
    size: '470.68 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Photos',
    year: 2012,
    size: '4.93 Gb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Stream Temperature',
    year: 2012,
    size: '785 Kb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Topographic',
    year: 2012,
    size: '556.17 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Photos',
    year: 2013,
    size: '4.15 Gb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Stream Temperature',
    year: 2013,
    size: '868 Kb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Topographic',
    year: 2013,
    size: '752.02 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Photos',
    year: 2014,
    size: '1.29 Gb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Stream Temperature',
    year: 2014,
    size: '1.53 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Topographic',
    year: 2014,
    size: '629.16 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Photos',
    year: 2015,
    size: '1.20 Gb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Stream Temperature',
    year: 2015,
    size: '1.39 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Topographic',
    year: 2015,
    size: '979.86 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Photos',
    year: 2016,
    size: '1.24 Gb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Stream Temperature',
    year: 2016,
    size: '2.91 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Topographic',
    year: 2016,
    size: '815.28 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Photos',
    year: 2017,
    size: '803.53 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Stream Temperature',
    year: 2017,
    size: '1.23 Mb'
  },
  {
    name: 'South Fork Salmon',
    data: 'Topographic',
    year: 2017,
    size: '513.46 Mb'
  }
];

export default SouthForkSalmon;
