const Asotin = [
    {
        name: 'Asotin',
        data: 'Photos',
        year: 2011,
        size: '1.40 Gb'
    },
    {
        name: 'Asotin',
        data: 'Stream Temperature',
        year: 2011,
        size: '359 Kb'
    },
    {
        name: 'Asotin',
        data: 'Topographic',
        year: 2011,
        size: '55.15 Mb'
    },
    {
        name: 'Asotin',
        data: 'Photos',
        year: 2012,
        size: '2.64 Gb'
    },
    {
        name: 'Asotin',
        data: 'Stream Temperature',
        year: 2012,
        size: '86 Kb'
    },
    {
        name: 'Asotin',
        data: 'Topographic',
        year: 2012,
        size: '211.78 Mb'
    },
    {
        name: 'Asotin',
        data: 'Photos',
        year: 2013,
        size: '2.89 Gb'
    },
    {
        name: 'Asotin',
        data: 'Stream Temperature',
        year: 2013,
        size: '242 Kb'
    },
    {
        name: 'Asotin',
        data: 'Topographic',
        year: 2013,
        size: '261.53 Mb'
    },
    {
        name: 'Asotin',
        data: 'Photos',
        year: 2014,
        size: '1.08 Gb'
    },
    {
        name: 'Asotin',
        data: 'Stream Temperature',
        year: 2014,
        size: '933 Kb'
    },
    {
        name: 'Asotin',
        data: 'Topographic',
        year: 2014,
        size: '343.25 Mb'
    },
    {
        name: 'Asotin',
        data: 'Photos',
        year: 2015,
        size: '774.23 Mb'
    },
    {
        name: 'Asotin',
        data: 'Stream Temperature',
        year: 2015,
        size: '719 Kb'
    },
    {
        name: 'Asotin',
        data: 'Topographic',
        year: 2015,
        size: '369.86 Mb'
    },
    {
        name: 'Asotin',
        data: 'Photos',
        year: 2016,
        size: '810.12 Mb'
    },
    {
        name: 'Asotin',
        data: 'Stream Temperature',
        year: 2016,
        size: '205 Kb'
    },
    {
        name: 'Asotin',
        data: 'Topographic',
        year: 2016,
        size: '386.94 Mb'
    },
    {
        name: 'Asotin',
        data: 'Photos',
        year: 2017,
        size: '781.33 Mb'
    },
    {
        name: 'Asotin',
        data: 'Topographic',
        year: 2017,
        size: '416.25 Mb'
    },
];

export default Asotin;