import React, { useRef, useCallback } from 'react';
import Map, { Source, Layer, FullscreenControl } from 'react-map-gl';
import watershedGeoJson from '../../utils/watershedGeoJson.json';
import 'mapbox-gl/dist/mapbox-gl.css';
import MarkerWithPopup from './MarkerWithPopup';

const TOKEN = 'pk.eyJ1IjoiZXNhZ2lzIiwiYSI6ImV3dnkyWjAifQ.SYBjFR-Bw0YDntotGjtZxQ';

function MapContainer() {
  const mapRef = useRef();
  const dataLayer = {
    id: 'watersheds-outline',
    source: 'watersheds',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#a7ca37',
      'line-opacity': 1,
      'line-width': 2
    }
  };

  const onSelectCity = useCallback(({ lng, lat }) => {
    mapRef.current?.flyTo({ center: [lng, lat], duration: 2000, zoom: 7, essential: true });
  }, []);

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        latitude: 46,
        longitude: -118,
        zoom: 4.5
      }}
      mapStyle="mapbox://styles/esagis/ck4t6gsva25tr1cpuwxvv2iej"
      mapboxAccessToken={TOKEN}
    >
      <Source type="geojson" data={watershedGeoJson}>
        <Layer {...dataLayer} />
        <FullscreenControl />
        {watershedGeoJson.features.map((watershed) => {
          return (
            <MarkerWithPopup
              watershed={watershed}
              key={watershed.properties.WatershedName}
              onSelectCity={onSelectCity}
            />
          );
        })}
      </Source>
    </Map>
  );
}

export default MapContainer;
