const JohnDay = [
  {
    name: 'John Day',
    data: 'Photos',
    year: 2011,
    size: '11.43 Gb'
  },
  {
    name: 'John Day',
    data: 'Stream Temperature',
    year: 2011,
    size: '347 Kb'
  },
  {
    name: 'John Day',
    data: 'Topographic',
    year: 2011,
    size: '263.36 Mb'
  },
  {
    name: 'John Day',
    data: 'Photos',
    year: 2012,
    size: '14.83 Gb'
  },
  {
    name: 'John Day',
    data: 'Stream Temperature',
    year: 2012,
    size: '1.79 Mb'
  },
  {
    name: 'John Day',
    data: 'Topographic',
    year: 2012,
    size: '877.95 Mb'
  },
  {
    name: 'John Day',
    data: 'Photos',
    year: 2013,
    size: '12.26 Gb'
  },
  {
    name: 'John Day',
    data: 'Stream Temperature',
    year: 2013,
    size: '1.14 Mb'
  },
  {
    name: 'John Day',
    data: 'Topographic',
    year: 2013,
    size: '1.35 Gb'
  },
  {
    name: 'John Day',
    data: 'Photos',
    year: 2014,
    size: '3.41 Gb'
  },
  {
    name: 'John Day',
    data: 'Stream Temperature',
    year: 2014,
    size: '1.59 Mb'
  },
  {
    name: 'John Day',
    data: 'Topographic',
    year: 2014,
    size: '1.14 Gb'
  },
  {
    name: 'John Day',
    data: 'Photos',
    year: 2015,
    size: '4.01 Gb'
  },
  {
    name: 'John Day',
    data: 'Stream Temperature',
    year: 2015,
    size: '3.21 Mb'
  },
  {
    name: 'John Day',
    data: 'Topographic',
    year: 2015,
    size: '1.94 Gb'
  },
  {
    name: 'John Day',
    data: 'Photos',
    year: 2016,
    size: '2.82 Gb'
  },
  {
    name: 'John Day',
    data: 'Stream Temperature',
    year: 2016,
    size: '4.91 Mb'
  },
  {
    name: 'John Day',
    data: 'Topographic',
    year: 2016,
    size: '1.52 Gb'
  },
  {
    name: 'John Day',
    data: 'Photos',
    year: 2017,
    size: '1.35 Gb'
  },
  {
    name: 'John Day',
    data: 'Stream Temperature',
    year: 2017,
    size: '2.63 Mb'
  },
  {
    name: 'John Day',
    data: 'Topographic',
    year: 2017,
    size: '597.51 Mb'
  },
  {
    name: 'John Day',
    data: 'Photos',
    year: 2018,
    size: '1.57 Gb'
  },
  {
    name: 'John Day',
    data: 'Stream Temperature',
    year: 2018,
    size: '2.50 Mb'
  }
];

export default JohnDay;
